import { useTheme, Box, Stack, Typography } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { removePartner } from 'components/ApiActions';

export const PartnerObject = ({ partner, index, refresh }) => {
  const theme = useTheme();

  const startRemovePartner = async () => {
    console.log("Remove partner");
    const result = await removePartner(partner.email);
    console.log(result);
    refresh();
  }
  return (
    <Box
      key={'partnerObject' + index}
      sx={{
        backgroundColor: theme.palette.grey[5000],
        color: theme.palette.grey[500],
        border: `1px solid ${'transparent'}`,
        '&:hover': {
          backgroundColor: theme.palette.grey[5000],
          color: theme.palette.grey[500],
          border: `1px solid ${theme.palette.primary.main}`, // Highlight border on hover
        },
        borderRadius: '20px',
        textTransform: 'none',
        margin: theme.spacing(1), // Add margin around buttons
        padding: '8px 8px', // Comfortable padding
        width: "100%",
        justifyContent: 'left',
        alignItems: 'center',
      }}>
      <Stack
        direction="column"
        spacing={2}
        justifyContent="left"
        alignItems="flex-start"
        useFlexGap
        flexWrap="wrap">
        <Typography>{partner.email} | Promo Kode: {partner.promoCode}</Typography>
        <Typography>Bonus: {partner.partnerBonus} | Cut: {partner.partnerCut} | Tokens: {partner.partnerTokensPaidOut}/{partner.partnerTokensSold} Balance: {((partner.partnerTokensSold - partner.partnerTokensPaidOut) / 5) * partner.partnerCut}kr!</Typography>
        <Delete onClick={() => { startRemovePartner() }} />
      </Stack>
    </Box>
  );
};
