import { useSelector } from 'react-redux';
import { ErrorScreen } from 'components/ErrorScreen';
import { WaitingScreen } from 'components/WaitingScreen';
import { FinishedScreen } from 'components/FinishedScreen';
import { SplashScreen } from 'components/SplashScreen';
import { GenerationScreen } from 'components/GenerationScreen';
import { SEO } from 'components/SEO';

export const Home = () => {
	const isWriting = useSelector((state) => state.global.isWriting);
	const isFinished = useSelector((state) => state.global.isFinished);
	const hasUserId = Boolean(
		useSelector((state) => state.global.token) !== null
	);
	const errorMessage = useSelector((state) => state.global.errorMessage);

	return (
		<>
		<SEO
		title="LønnLyst - AI gjør designekspertise tilgjengelig for alle!"
		description="Gjør dine designambisjoner til virkelighet med AI-teknologi som forstår din stil og visjoner."
		name="Lønnlyst"
		type="tool" />
			{isWriting ? (
				<WaitingScreen />
			) : isFinished ? (
				<FinishedScreen />
			) : errorMessage !== '' ? (
				<ErrorScreen />
			) : hasUserId ? (
				<GenerationScreen />
			) : (
				<SplashScreen/>
			)}
		</>
	);
};