import * as React from 'react';
import {
	useTheme,
	Chip,
	Typography,
	Button,
	Stack,
	Snackbar,
	Alert,
	Paper,
	Backdrop,
	ButtonGroup,
	CircularProgress
} from '@mui/material';
import { saveAs } from 'file-saver';
import { SaveAs, Share, OpenInFull,
	Favorite,
	FavoriteBorder, } from '@mui/icons-material';
import { CustomButton } from './CustomButton';
import { styled } from '@mui/material/styles';
import { favoriteGeneratedObject } from './ApiActions';

export const GeneratedImage = ({ image }) => {
	const theme = useTheme();
	const [open, setOpen] = React.useState(false);
	const [isFavorite, setIsFavorite] = React.useState(image.favorite);
	const [isLoadingFavorite, setIsLoadignFavorite] = React.useState(false);
	const handleClose = () => {
	  setOpen(false);
	};
	const handleOpen = () => {
	  setOpen(true);
	};

	const handleSave = async () => {
		try {
			const response = await fetch(
				'https://pixygon-server.onrender.com/aiusers/getImage?' +
					new URLSearchParams({
						url: image.generatedImage,
					})
			);
			if (!response.ok) {
				throw new Error('Failed to fetch image');
			}
			const imageBlob = await response.blob();
			saveAs(URL.createObjectURL(imageBlob), 'LonnlystBilde.png');
		} catch (err) {
			console.error('Error sharing:', err);
		}
		return;
	};
	const handleShare = async () => {
		try {
			const response = await fetch(
				'https://pixygon-server.onrender.com/aiusers/getImage?' +
					new URLSearchParams({
						url: image.generatedImage,
					})
			);
			if (!response.ok) {
				throw new Error('Failed to fetch image');
			}
			const imageBlob = await response.blob();
			const data = {
				files: [
					new File([imageBlob], 'image.png', {
						type: imageBlob.type,
					}),
				],
				title: 'Redesignet Interiør fra LønnLyst.no',
				text: 'Nytt interiør fra Lønnlyst!',
				url: "https://www.lonnlyst.no"
			};

			if (!navigator.canShare(data)) {
				console.error("Can't share");
				return;
			}
			await navigator.share(data);
		} catch (err) {
			console.error('Error sharing:', err);
		}
	};
	const handleFavorite = async () => {
		if(isLoadingFavorite) return;
		setIsLoadignFavorite(true);
		setIsFavorite(!isFavorite);
		const response = await favoriteGeneratedObject(image._id);
		setIsLoadignFavorite(false);
	};
	const ToggleButton = styled(Button)(({ theme }) => ({
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.text.tertiary,
		textTransform: 'none',
		padding: '8px 8px',
		flex: "auto"
	}));
	return (
		<Stack
			mt="1rem"
			direction="column"
			spacing={2}
			justifyContent="center"
			alignItems="center"
			useFlexGap
			flexWrap="wrap"
			padding="0.2rem">
			<Typography
				textAlign="center"
				variant="h1"
				sx={{
					color: theme.palette.grey[500],
				}}>
				Generert bilde
			</Typography>
			<Stack
				direction="row"
				spacing="0.2rem"
				justifyContent="flex-start"
				alignItems="center"
				useFlexGap
				flexWrap="wrap"
				padding="0.2rem"
				width="100%">
					{console.log(image.keywords)}
				<Chip
					color="secondary"
					label={
						<>
							<b>Type: </b> <>{image.keywords[0]}</>
						</>
					}
				/>
				<Chip
					color="secondary"
					label={
						<>
							<b>Rom: </b> <>{image.keywords[1]}</>
						</>
					}
				/>
				<Chip
					color="secondary"
					label={
						<>
							<b>Stil: </b> <>{image.keywords[2]}</>
						</>
					}
				/>
				<Chip
					color="secondary"
					label={
						<>
							<b>Ai-bruk: </b> <>{image.keywords[3]}</>
						</>
					}
				/>
			</Stack>
			<Paper
				sx={{
					backgroundImage: `url(${image.generatedImage})`,
					backgroundSize: 'contain',
					backgroundPosition: 'center',
					backgroundRepeat: 'no-repeat',
					width: '100%',
					height: '500px',
				}}>
				<Stack
					direction="row"
					spacing="0.2rem"
					justifyContent="flex-end"
					alignItems="center"
					useFlexGap
					flexWrap="wrap"
					padding="0.2rem">
					<ButtonGroup orientation="vertical" variant="contained">
							<ToggleButton onClick={handleOpen} ><OpenInFull /> </ToggleButton>
							<ToggleButton onClick={handleFavorite}> {isLoadingFavorite ? <CircularProgress size={20} /> : (
								isFavorite ? <Favorite /> : <FavoriteBorder />
							)}
							</ToggleButton>
					<ToggleButton
						onClick={() => {
							handleShare();
						}}>
						<Share sx={{ color: theme.palette.primary }} />
					</ToggleButton>
					<ToggleButton
						onClick={() => {
							handleSave();
						}}>
						<SaveAs sx={{ color: theme.palette.primary }} />
					</ToggleButton>
						</ButtonGroup>
				</Stack>
			</Paper>

<Backdrop
sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
open={open}
onClick={handleClose}
>
<img
					height="auto"
					width="90%"
					src={image.generatedImage}
				/>
</Backdrop>
		</Stack>
	);
};
