export const styleObjects = [
  { label: "No Style", label_nor: "Ingen stil", index: 0 },
  { label: "Eclectic", label_nor: "Eklektisk", index: 1 },
  { label: "Modern", label_nor: "Moderne", index: 2 },
  { label: "Contemporary", label_nor: "Samtidskunst", index: 3 },
  { label: "Transitional", label_nor: "Transisjonell", index: 4 },
  { label: "Scandinavian", label_nor: "Skandinavisk", index: 5 },
  { label: "Mediterranean", label_nor: "Middelhavsstil", index: 6 },
  { label: "Ikea", label_nor: "Ikea", index: 7 },
  { label: "Industrial", label_nor: "Industriell", index: 8 },
  { label: "Kids Room", label_nor: "Barnerom", index: 9 },
  { label: "Shabby Chic", label_nor: "Slitt Chic", index: 10 },
  { label: "Coastal", label_nor: "Kystnær", index: 11 },
  { label: "Bauhaus", label_nor: "Bauhaus", index: 12 },
  { label: "Bohemian", label_nor: "Bohem", index: 13 },
  { label: "Traditional", label_nor: "Tradisjonell", index: 14 },
  { label: "Rustic", label_nor: "Rustikk", index: 15 },
  { label: "Minimalism", label_nor: "Minimalisme", index: 16 },
  { label: "Japandi", label_nor: "Japandi", index: 17 },
  { label: "Japanese Design", label_nor: "Japansk design", index: 18 },
  { label: "Modern Arabic", label_nor: "Moderne arabisk", index: 19 },
  { label: "Traditional Arabic", label_nor: "Tradisjonell arabisk", index: 20 },
  { label: "Bali", label_nor: "Bali", index: 21 },
  { label: "Tropical", label_nor: "Tropisk", index: 22 },
  { label: "Asian Decor", label_nor: "Asiatisk dekor", index: 23 },
  { label: "Zen", label_nor: "Zen", index: 24 },
  { label: "Hollywood Regency", label_nor: "Hollywood Regency", index: 25 },
  { label: "Hollywood Glam", label_nor: "Hollywood Glam", index: 26 },
  { label: "Minimalist", label_nor: "Minimalistisk", index: 27 },
  { label: "Christmas", label_nor: "Jule", index: 28 },
  { label: "Futuristic", label_nor: "Futuristisk", index: 29 },
  { label: "Luxurious", label_nor: "Luksuriøs", index: 30 },
  { label: "Midcentury Modern", label_nor: "Midtcentury Moderne", index: 31 },
  { label: "Biophilic", label_nor: "Biophilic", index: 32 },
  { label: "Cottage Core", label_nor: "Cottage Core", index: 33 },
  { label: "French Country", label_nor: "Fransk landstil", index: 34 },
  { label: "Art Deco", label_nor: "Art Deco", index: 35 },
  { label: "Art Nouveau", label_nor: "Art Nouveau", index: 36 },
  { label: "South Western", label_nor: "Sørvestlig", index: 37 },
  { label: "Modern Farm House", label_nor: "Moderne bondegård", index: 38 },
  { label: "Moroccan", label_nor: "Marokkansk", index: 39 },
  { label: "Gothic", label_nor: "Gotisk", index: 40 },
  { label: "Victorian", label_nor: "Viktoriansk", index: 41 },
  { label: "Steampunk", label_nor: "Steampunk", index: 42 },
  { label: "Urban Modern", label_nor: "Urban Moderne", index: 43 },
  { label: "Desert Modernism", label_nor: "Ørkenmodernisme", index: 44 },
  { label: "Colonial", label_nor: "Kolonial", index: 45 },
  { label: "Brutalist", label_nor: "Brutalist", index: 46 },
  { label: "Nordic Noir", label_nor: "Nordisk Noir", index: 47 },
  { label: "Postmodern", label_nor: "Postmoderne", index: 48 },
  { label: "Psychedelic", label_nor: "Psykedelisk", index: 49 },
  { label: "Cosmic Chic", label_nor: "Kosmisk Chic", index: 50 },
  { label: "Mexican Hacienda", label_nor: "Meksikansk Hacienda", index: 51 },
  { label: "Coastal Modern", label_nor: "Moderne Kystnær", index: 52 },
  { label: "Eco Friendly", label_nor: "Miljøvennlig", index: 53 },
  { label: "Pop Art", label_nor: "Pop Art", index: 54 },
  { label: "Vintage Glam", label_nor: "Vintage Glam", index: 55 },
  { label: "Candy Land", label_nor: "Candy Land", index: 56 },
  { label: "Barbie", label_nor: "Barbie", index: 57 },
  { label: "Doodle", label_nor: "Doodle", index: 58 },
  { label: "Sketch", label_nor: "Skisse", index: 59 },
  { label: "Maximalist", label_nor: "Maksimalist", index: 60 },
  { label: "Professional", label_nor: "Profesjonell", index: 61 },
  { label: "AirBnb", label_nor: "AirBnb", index: 62 },
  { label: "Halloween", label_nor: "Halloween", index: 63 },
  { label: "Retro", label_nor: "Retro", index: 64 },
  { label: "Romantic", label_nor: "Romantisk", index: 65 },
  { label: "Glam Rock", label_nor: "Glam Rock", index: 66 },
  { label: "Safari", label_nor: "Safari", index: 67 },
  { label: "Tuscan", label_nor: "Toskansk", index: 68 },
  { label: "Nautical", label_nor: "Nautisk", index: 69 },
  { label: "Craftman", label_nor: "Craftman", index: 70 },
  { label: "Farmhouse Chic", label_nor: "Farmhouse Chic", index: 71 },
  { label: "Prairie", label_nor: "Prairie", index: 72 },
  { label: "Cubism", label_nor: "Kubisme", index: 73 },
];
export const styleExteriorObjects = [
  { label: "No Style", label_nor: "Ingen stil", index: 0 },
  { label: "Modern", label_nor: "Moderne", index: 1 },
  { label: "Mediterranean", label_nor: "Middelhavs", index: 2 },
  { label: "International", label_nor: "Internasjonal", index: 3 },
  { label: "Moody Colors", label_nor: "Melankolske farger", index: 4 },
  { label: "Wood Accents", label_nor: "Tre aksenter", index: 5 },
  { label: "Bohemian", label_nor: "Bohem", index: 6 },
  { label: "Industrial", label_nor: "Industriell", index: 7 },
  { label: "Retreat", label_nor: "Retreat", index: 8 },
  { label: "Elegant", label_nor: "Elegant", index: 9 },
  { label: "Painted Brick", label_nor: "Malt murstein", index: 10 },
  { label: "Red Brick", label_nor: "Rød murstein", index: 11 },
  { label: "Modern Blend", label_nor: "Moderne blandet", index: 12 },
  { label: "Stone Clad", label_nor: "Stein kledd", index: 13 },
  { label: "Glass House", label_nor: "Glashus", index: 14 },
  { label: "Ranch", label_nor: "Ranch", index: 15 },
  { label: "Modern Farm House", label_nor: "Moderne gårdshus", index: 16 },
  { label: "Portuguese", label_nor: "Portugisisk", index: 17 },
  { label: "Traditional", label_nor: "Tradisjonell", index: 18 },
  { label: "Craftsman", label_nor: "Håndverker", index: 19 },
  { label: "Tudor", label_nor: "Tudor", index: 20 },
  { label: "Prairie", label_nor: "Prærie", index: 21 },
  { label: "Chalet", label_nor: "Chalet", index: 22 },
  { label: "Colonial", label_nor: "Kolonial", index: 23 },
  {
    label: "Dutch Colonial Revival",
    label_nor: "Hollandsk kolonial gjenfødelse",
    index: 24,
  },
  { label: "Georgian", label_nor: "Georgiansk", index: 25 },
  { label: "Green", label_nor: "Grønn", index: 26 },
  { label: "Contemporary", label_nor: "Samtids", index: 27 },
  { label: "Cottage", label_nor: "Cottage", index: 28 },
  { label: "Farmhouse", label_nor: "Gårdshus", index: 29 },
  { label: "French Country", label_nor: "Fransk land", index: 30 },
  { label: "Futuristic", label_nor: "Futuristisk", index: 31 },
  { label: "Gothic", label_nor: "Gotisk", index: 32 },
  { label: "Greek Revival", label_nor: "Gresk gjenfødelse", index: 33 },
  { label: "Mansion", label_nor: "Herskapshus", index: 34 },
  { label: "Townhouse", label_nor: "Byhus", index: 35 },
  { label: "Victorian", label_nor: "Viktoriansk", index: 36 },
  { label: "Corporate Building", label_nor: "Forretningsbygg", index: 37 },
];
export const styleGardenObjects = [
  { label: "No Style", label_nor: "Ingen stil", index: 0 },
  { label: "Modern", label_nor: "Moderne", index: 1 },
  { label: "City", label_nor: "By", index: 2 },
  { label: "Contemporary", label_nor: "Samtidshage", index: 3 },
  { label: "Luxury", label_nor: "Luksuriøs", index: 4 },
  { label: "Apartment", label_nor: "Leilighet", index: 5 },
  { label: "Small", label_nor: "Liten", index: 6 },
  { label: "Vegetable", label_nor: "Grønnsakshage", index: 7 },
  { label: "Low Budget", label_nor: "Lavbudsjett", index: 8 },
  { label: "Beach", label_nor: "Strand", index: 9 },
  { label: "Wedding", label_nor: "Bryllup", index: 10 },
  { label: "Rural", label_nor: "Landlig", index: 11 },
  { label: "Mediterranean", label_nor: "Middelhavet", index: 12 },
  { label: "Restaurant", label_nor: "Restaurant", index: 13 },
  { label: "Formal", label_nor: "Formell", index: 14 },
  { label: "American", label_nor: "Amerikansk", index: 15 },
  { label: "English", label_nor: "Engelsk", index: 16 },
  { label: "Traditional", label_nor: "Tradisjonell", index: 17 },
  { label: "Cottage", label_nor: "Cottage", index: 18 },
  { label: "Meditation", label_nor: "Meditasjon", index: 19 },
  { label: "Coastal", label_nor: "Kyst", index: 20 },
  { label: "Tropical", label_nor: "Tropisk", index: 21 },
];
export const roomObjects = [
  { label: "Living room", label_nor: "Stue", index: 0 },
  { label: "Bedroom", label_nor: "Soverom", index: 1 },
  { label: "Bathroom", label_nor: "Bad", index: 2 },
  { label: "Kitchen", label_nor: "Kjøkken", index: 3 },
  { label: "Dining room", label_nor: "Spisestue", index: 4 },
  { label: "Attic", label_nor: "Loft", index: 5 },
  { label: "Study room", label_nor: "Studierom", index: 6 },
  { label: "Home office", label_nor: "Hjemmekontor", index: 7 },
  { label: "Family Room", label_nor: "Familierom", index: 8 },
  { label: "Formal Dining Room", label_nor: "Formell spisestue", index: 9 },
  { label: "Home Theater", label_nor: "Hjemmekino", index: 10 },
  { label: "Kids Room", label_nor: "Barnerom", index: 11 },
  { label: "Balcony", label_nor: "Balkong", index: 12 },
  { label: "Gaming room", label_nor: "Spillerom", index: 13 },
  { label: "Meeting room", label_nor: "Møterom", index: 14 },
  { label: "Workshop", label_nor: "Verksted", index: 15 },
  { label: "Fitness gym", label_nor: "Treningssenter", index: 16 },
  { label: "Coffee shop", label_nor: "Kafé", index: 17 },
  { label: "Clothing store", label_nor: "Klesbutikk", index: 18 },
  { label: "Restaurant", label_nor: "Restaurant", index: 19 },
  { label: "Office", label_nor: "Kontor", index: 20 },
  { label: "Coworking space", label_nor: "Coworking space", index: 21 },
  { label: "Hotel lobby", label_nor: "Hotell lobby", index: 22 },
  { label: "Hotel room", label_nor: "Hotellrom", index: 23 },
  { label: "Hotel bathroom", label_nor: "Hotell bad", index: 24 },
  { label: "Exhibition space", label_nor: "Utstillingsrom", index: 25 },
  { label: "Onsen", label_nor: "Onsen", index: 26 },
  { label: "Working Space", label_nor: "Arbeidsrom", index: 27 },
  { label: "Wine Cellar", label_nor: "Vinkjeller", index: 28 },
  { label: "Rooftop Terrace", label_nor: "Takterrasse", index: 29 },
  { label: "Sunroom", label_nor: "Vinterhage", index: 30 },
  { label: "Home Spa", label_nor: "Hjemme-spa", index: 31 },
  { label: "Mudroom", label_nor: "Yttergang", index: 32 },
  { label: "Craft Room", label_nor: "Hobbyrom", index: 33 },
  { label: "Dressing Room", label_nor: "Garderobe", index: 34 },
  { label: "Guest Bedroom", label_nor: "Gjesterom", index: 35 },
  { label: "Home Bar", label_nor: "Hjemmebar", index: 36 },
  { label: "Library", label_nor: "Bibliotek", index: 37 },
  { label: "Art Studio", label_nor: "Kunststudio", index: 38 },
  { label: "Yoga Studio", label_nor: "Yogastudio", index: 39 },
  { label: "Photo Studio", label_nor: "Fotostudio", index: 40 },
  { label: "Multimedia Room", label_nor: "Multimediastue", index: 41 },
  { label: "Auditorium", label_nor: "Auditorium", index: 42 },
  {
    label: "Medical Exam Room",
    label_nor: "Medisinsk undersøkelsesrom",
    index: 43,
  },
  { label: "Reception Area", label_nor: "Resepsjonsområde", index: 44 },
  { label: "Music Room", label_nor: "Musikkrom", index: 45 },
  {
    label: "Science Laboratory",
    label_nor: "Vitenskapslaboratorium",
    index: 46,
  },
  { label: "Wedding Room", label_nor: "Bryllupsrom", index: 47 },
  { label: "Laundry Room", label_nor: "Vaskerom", index: 48 },
  { label: "Outdoor Kitchen", label_nor: "Utekjøkken", index: 49 },
  { label: "Utility Room", label_nor: "Bøttekott", index: 50 },
  { label: "Pet Room", label_nor: "Kjæledyrsrom", index: 51 },
  { label: "Home Gym", label_nor: "Hjemmegym", index: 52 },
  { label: "Lounge", label_nor: "Lounge", index: 53 },
  { label: "Walk In Closet", label_nor: "Walk-in Garderobe", index: 54 },
  { label: "Playroom", label_nor: "Lekerom", index: 55 },
  { label: "Reading Nook", label_nor: "Lesekrok", index: 56 },
  { label: "Sauna", label_nor: "Badstue", index: 57 },
  { label: "Man Cave", label_nor: "Man Cave", index: 58 },
  { label: "Foyer", label_nor: "Entré", index: 59 },
  { label: "Greenhouse", label_nor: "Drivhus", index: 60 },
  { label: "She Shed", label_nor: "She Shed", index: 61 },
  { label: "Conservatory", label_nor: "Oransjeri", index: 62 },
  { label: "Nursery", label_nor: "Babyrom", index: 63 },
  { label: "Prayer room", label_nor: "Bønnerom", index: 64 },
];
export const roomExteriorObjects = [
  { label: "Side of House", label_nor: "Side av hus", index: 0 },
  { label: "Front of House", label_nor: "Front av hus", index: 1 },
  { label: "Back of House", label_nor: "Bakside av hus", index: 2 },
];
export const roomGardenObjects = [
  { label: "Backyard", label_nor: "Bakgård", index: 0 },
  { label: "Patio", label_nor: "Patio", index: 1 },
  { label: "Terrace", label_nor: "Terrasse", index: 2 },
  { label: "Front Yard", label_nor: "Forsidegård", index: 3 },
  { label: "Garden", label_nor: "Hage", index: 4 },
  { label: "Courtyard", label_nor: "Gårdsplass", index: 5 },
  { label: "Pool Area", label_nor: "Bassengområde", index: 6 },
  { label: "Porch", label_nor: "Veranda", index: 7 },
  { label: "Playground", label_nor: "Lekeplass", index: 8 },
];
export const colorObjects = [
  { label: "White", label_nor: "Hvit", index: 0 },
  { label: "Black", label_nor: "Svart", index: 1 },
  { label: "Grey", label_nor: "Grå", index: 2 },
  { label: "Blue", label_nor: "Blå", index: 3 },
  { label: "Red", label_nor: "Rød", index: 4 },
  { label: "Green", label_nor: "Grønn", index: 5 },
  { label: "Yellow", label_nor: "Gul", index: 6 },
  { label: "Brown", label_nor: "Brun", index: 7 },
  { label: "Purple", label_nor: "Lilla", index: 8 },
  { label: "Pink", label_nor: "Rosa", index: 9 },
  { label: "Almond", label_nor: "Mandel", index: 10 },
  { label: "Amethyst", label_nor: "Ametyst", index: 11 },
  { label: "Amber", label_nor: "Rav", index: 12 },
  { label: "Anthracite Grey", label_nor: "Antrasittgrå", index: 13 },
  { label: "Aqua", label_nor: "Akvamarin", index: 14 },
  { label: "Azure", label_nor: "Asurblå", index: 15 },
  { label: "Baby Blue", label_nor: "Babyblå", index: 16 },
  { label: "Beige", label_nor: "Beige", index: 17 },
  { label: "Biscuit", label_nor: "Kjeksfarge", index: 18 },
  { label: "Blizzard Blue", label_nor: "Blizzardblå", index: 19 },
  { label: "Blush", label_nor: "Blush", index: 20 },
  { label: "Bottle Green", label_nor: "Flaskeblå", index: 21 },
  { label: "Brass", label_nor: "Messing", index: 22 },
  { label: "Bronze", label_nor: "Bronse", index: 23 },
  { label: "Burgundy", label_nor: "Burgunder", index: 24 },
  { label: "Butterscotch", label_nor: "Karamell", index: 25 },
  { label: "Camel", label_nor: "Kamel", index: 26 },
  { label: "Celadon", label_nor: "Celadon", index: 27 },
  { label: "Cerise", label_nor: "Cerise", index: 28 },
  { label: "Cerulean", label_nor: "Cerulean", index: 29 },
  { label: "Champagne", label_nor: "Champagne", index: 30 },
  { label: "Charcoal", label_nor: "Kull", index: 31 },
  { label: "Chartreuse", label_nor: "Chartreuse", index: 32 },
  { label: "Chestnut", label_nor: "Kastanje", index: 33 },
  { label: "Chiffon", label_nor: "Chiffon", index: 34 },
  { label: "Chocolate", label_nor: "Sjokolade", index: 35 },
  { label: "Cinnamon", label_nor: "Kanel", index: 36 },
  { label: "Claret", label_nor: "Claret", index: 37 },
  { label: "Cobalt Blue", label_nor: "Koboltblå", index: 38 },
  { label: "Copper", label_nor: "Kobber", index: 39 },
  { label: "Copper Red", label_nor: "Kobberrød", index: 40 },
  { label: "Coral", label_nor: "Korall", index: 41 },
  { label: "Cornflower Blue", label_nor: "Kornblomstblå", index: 42 },
  { label: "Cream", label_nor: "Krem", index: 43 },
  { label: "Crimson", label_nor: "Karmosinrød", index: 44 },
  { label: "Cyan", label_nor: "Cyan", index: 45 },
  { label: "Deep Red", label_nor: "Dyp rød", index: 46 },
  { label: "Denim", label_nor: "Denim", index: 47 },
  { label: "Desert Sand", label_nor: "Ørkensand", index: 48 },
  { label: "Dusty Rose", label_nor: "Støvete rose", index: 49 },
  { label: "Electric Blue", label_nor: "Elektrisk blå", index: 50 },
  { label: "Emerald", label_nor: "Smaragd", index: 51 },
  { label: "Emerald Green", label_nor: "Smaragdgrønn", index: 52 },
  { label: "Flamingo Pink", label_nor: "Flamingorosa", index: 53 },
  { label: "Forest Green", label_nor: "Skoggrønn", index: 54 },
  { label: "Frost Blue", label_nor: "Frostblå", index: 55 },
  { label: "Fuchsia", label_nor: "Fuksia", index: 56 },
  { label: "Ginger", label_nor: "Ingefær", index: 57 },
  { label: "Gold", label_nor: "Gull", index: 58 },
  { label: "Goldenrod", label_nor: "Gylden", index: 59 },
  { label: "Granite Grey", label_nor: "Granitgrå", index: 60 },
  { label: "Graphite", label_nor: "Grafitt", index: 61 },
  { label: "Harlequin", label_nor: "Harlekin", index: 62 },
  { label: "Heather", label_nor: "Lyng", index: 63 },
  { label: "Honeydew", label_nor: "Honningmelon", index: 64 },
  { label: "Ice Blue", label_nor: "Isblå", index: 65 },
  { label: "Ivory", label_nor: "Elfenben", index: 66 },
  { label: "Jade", label_nor: "Jade", index: 67 },
  { label: "Jasmine", label_nor: "Jasmin", index: 68 },
  { label: "Kelly Green", label_nor: "Kellygrønn", index: 69 },
  { label: "Khaki", label_nor: "Khaki", index: 70 },
  { label: "Lavender", label_nor: "Lavendel", index: 71 },
  { label: "Lemon Yellow", label_nor: "Sitron", index: 72 },
  { label: "Lime Green", label_nor: "Limegrønn", index: 73 },
  { label: "Linen", label_nor: "Lin", index: 74 },
  { label: "Lilac", label_nor: "Syrin", index: 75 },
  { label: "Magenta", label_nor: "Magenta", index: 76 },
  { label: "Mahogany", label_nor: "Mahogni", index: 77 },
  { label: "Maroon", label_nor: "Vinrød", index: 78 },
  { label: "Mauve", label_nor: "Mauve", index: 79 },
  { label: "Midnight Blue", label_nor: "Midnattsblå", index: 80 },
  { label: "Mint Green", label_nor: "Mintgrønn", index: 81 },
  { label: "Mulberry", label_nor: "Morbær", index: 82 },
  { label: "Mustard Yellow", label_nor: "Sennepsgul", index: 83 },
  { label: "Neon Green", label_nor: "Neongrønn", index: 84 },
  { label: "Navy Blue", label_nor: "Marineblå", index: 85 },
  { label: "Ocean Blue", label_nor: "Havblå", index: 86 },
  { label: "Ochre", label_nor: "Okergul", index: 87 },
  { label: "Olive", label_nor: "Oliven", index: 88 },
  { label: "Olive Drab", label_nor: "Olivengrønn", index: 89 },
  { label: "Onyx", label_nor: "Onyx", index: 90 },
  { label: "Pastel Pink", label_nor: "Pastellrosa", index: 91 },
  { label: "Pearl", label_nor: "Perle", index: 92 },
  { label: "Periwinkle", label_nor: "Periwinkle", index: 93 },
  { label: "Pewter", label_nor: "Tinn", index: 94 },
  { label: "Pine Green", label_nor: "Furugrønn", index: 95 },
  { label: "Pistachio", label_nor: "Pistasj", index: 96 },
  { label: "Plum", label_nor: "Plomme", index: 97 },
  { label: "Powder Blue", label_nor: "Pudderblå", index: 98 },
  { label: "Powder Pink", label_nor: "Pudderrosa", index: 99 },
  { label: "Rose Pink", label_nor: "Rosenrosa", index: 100 },
  { label: "Ruby", label_nor: "Rubinrød", index: 101 },
  { label: "Rust", label_nor: "Rust", index: 102 },
  { label: "Saffron", label_nor: "Safran", index: 103 },
  { label: "Salmon", label_nor: "Lakserosa", index: 104 },
  { label: "Sand", label_nor: "Sand", index: 105 },
  { label: "Sapphire Blue", label_nor: "Safirblå", index: 106 },
  { label: "Seafoam Green", label_nor: "Skumgrønn", index: 107 },
  { label: "Sepia", label_nor: "Sepia", index: 108 },
  { label: "Slate Grey", label_nor: "Skifergrå", index: 109 },
  { label: "Smoke Grey", label_nor: "Røykgrå", index: 110 },
  { label: "Snow White", label_nor: "Snøhvit", index: 111 },
  { label: "Soft Pink", label_nor: "Mykrosa", index: 112 },
  { label: "Sorbet", label_nor: "Sorbet", index: 113 },
  { label: "Spruce Green", label_nor: "Grangrønn", index: 114 },
  { label: "Steel Blue", label_nor: "Stålblå", index: 115 },
  { label: "Sunflower Yellow", label_nor: "Solsikkegul", index: 116 },
  { label: "Sunset Orange", label_nor: "Solnedgang", index: 117 },
  { label: "Tan", label_nor: "Gyldenbrun", index: 118 },
  { label: "Taupe", label_nor: "Taupe", index: 119 },
  { label: "Teal", label_nor: "Teal", index: 120 },
  { label: "Tomato Red", label_nor: "Tomatrød", index: 121 },
  { label: "Topaz", label_nor: "Topas", index: 122 },
  { label: "Turquoise", label_nor: "Turkis", index: 123 },
  { label: "Ultramarine", label_nor: "Ultramarin", index: 124 },
  { label: "Vanilla", label_nor: "Vanilje", index: 125 },
  { label: "Verdigris", label_nor: "Patinagrønn", index: 126 },
  { label: "Vermilion", label_nor: "Kinalakk", index: 127 },
  { label: "Violet", label_nor: "Fiolett", index: 128 },
];
export const materialObjects = [
  { label: "Fabrics|Cotton", label_nor: "Bomull", index: 1 },
  { label: "Fabrics|Linen", label_nor: "Linstoff", index: 2 },
  { label: "Fabrics|Silk", label_nor: "Silke", index: 3 },
  { label: "Fabrics|Velvet", label_nor: "Fløyel", index: 4 },
  { label: "Fabrics|Wool", label_nor: "Ull", index: 5 },
  { label: "Fabrics|Leather", label_nor: "Lær", index: 6 },
  { label: "Fabrics|Suede", label_nor: "Semsket", index: 7 },
  { label: "Fabrics|Tweed", label_nor: "Tweed", index: 8 },
  { label: "Fabrics|Chenille", label_nor: "Chenille", index: 9 },
  { label: "Fabrics|Jacquard", label_nor: "Jacquard", index: 10 },
  { label: "Fabrics|Satin", label_nor: "Sateng", index: 11 },
  { label: "Fabrics|Polyester", label_nor: "Polyester", index: 12 },
  { label: "Fabrics|Nylon", label_nor: "Nylon", index: 13 },
  { label: "Fabrics|Rayon", label_nor: "Rayon", index: 14 },
  { label: "Fabrics|Cashmere", label_nor: "Kasjmir", index: 15 },
  { label: "Glass|Clear", label_nor: "Klart glass", index: 16 },
  { label: "Glass|Frosted", label_nor: "Frostet glass", index: 17 },
  { label: "Glass|Stained", label_nor: "Farget glass", index: 18 },
  { label: "Glass|Tempered", label_nor: "Herdet glass", index: 19 },
  { label: "Glass|Laminated", label_nor: "Laminert glass", index: 20 },
  { label: "Glass|Shattered", label_nor: "Knust glass", index: 21 },
  { label: "Glass|Patterned", label_nor: "Mønstret glass", index: 22 },
  { label: "Glass|Textured", label_nor: "Teksturert glass", index: 23 },
  { label: "Glass|Seeded", label_nor: "Frosset glass", index: 24 },
  { label: "Glass|Colored", label_nor: "Farget glass", index: 25 },
  { label: "Glass|Mirrored", label_nor: "Speilglass", index: 26 },
  { label: "Glass|Etched", label_nor: "Syret glass", index: 27 },
  { label: "Glass|Smoked", label_nor: "Røykfarget glass", index: 28 },
  { label: "Glass|Beveled", label_nor: "Skråkantet glass", index: 29 },
  { label: "Glass|Lead Crystal", label_nor: "Blykrystall", index: 30 },
  { label: "Glass|Obscured", label_nor: "Skjult glass", index: 31 },
  { label: "Wood|Oak", label_nor: "Eik", index: 32 },
  { label: "Wood|Maple", label_nor: "Lønn", index: 33 },
  { label: "Wood|Cherry", label_nor: "Kirsebær", index: 34 },
  { label: "Wood|Walnut", label_nor: "Valnøtt", index: 35 },
  { label: "Wood|Pine", label_nor: "Furu", index: 36 },
  { label: "Wood|Teak", label_nor: "Teak", index: 37 },
  { label: "Wood|Mahogany", label_nor: "Mahogni", index: 38 },
  { label: "Wood|Bamboo", label_nor: "Bambus", index: 39 },
  { label: "Wood|Birch", label_nor: "Bjørk", index: 40 },
  { label: "Wood|Rosewood", label_nor: "Rosentre", index: 41 },
  { label: "Wood|Ash", label_nor: "Ask", index: 42 },
  { label: "Wood|Cedar", label_nor: "Cedar", index: 43 },
  { label: "Wood|Hickory", label_nor: "Hickory", index: 44 },
  { label: "Wood|Alder", label_nor: "Alm", index: 45 },
  { label: "Wood|Ebony", label_nor: "Ebenholts", index: 46 },
  { label: "Stone|Marble", label_nor: "Marmor", index: 47 },
  { label: "Stone|Granite", label_nor: "Granitt", index: 48 },
  { label: "Stone|Bricks", label_nor: "Murstein", index: 49 },
  { label: "Stone|Red Bricks", label_nor: "Rød murstein", index: 50 },
  { label: "Stone|Limestone", label_nor: "Kalkstein", index: 51 },
  { label: "Stone|Slate", label_nor: "Skifer", index: 52 },
  { label: "Stone|Travertine", label_nor: "Travertin", index: 53 },
  { label: "Stone|Sandstone", label_nor: "Sandstein", index: 54 },
  { label: "Stone|Quartz", label_nor: "Kvarts", index: 55 },
  { label: "Stone|Onyx", label_nor: "Onyx", index: 56 },
  { label: "Stone|Basalt", label_nor: "Basalt", index: 57 },
  { label: "Stone|Terrazzo", label_nor: "Terrazzo", index: 58 },
  { label: "Stone|Soapstone", label_nor: "Såpestensplate", index: 59 },
  { label: "Stone|Soapstone", label_nor: "Såpestensplate", index: 60 },
  { label: "Stone|Dolomite", label_nor: "Dolomitt", index: 61 },
  { label: "Stone|Gneiss", label_nor: "Gneis", index: 62 },
  { label: "Stone|Bluestone", label_nor: "Blåsten", index: 63 },
  { label: "Metal|Steel", label_nor: "Stål", index: 64 },
  { label: "Metal|Aluminum", label_nor: "Aluminium", index: 65 },
  { label: "Metal|Brass", label_nor: "Messing", index: 66 },
  { label: "Metal|Wrought Iron", label_nor: "Smijern", index: 67 },
  { label: "Metal|Stainless Steel", label_nor: "Rustfritt stål", index: 68 },
  { label: "Metal|Chrome", label_nor: "Krom", index: 69 },
  { label: "Metal|Gold", label_nor: "Gull", index: 70 },
  { label: "Metal|Silver", label_nor: "Sølv", index: 71 },
  { label: "Metal|Copper", label_nor: "Kobber", index: 72 },
  { label: "Metal|Bronze", label_nor: "Bronse", index: 73 },
  { label: "Metal|Titanium", label_nor: "Titan", index: 74 },
  { label: "Metal|Zinc", label_nor: "Sink", index: 75 },
  { label: "Metal|Nickel", label_nor: "Nikkel", index: 76 },
  { label: "Metal|Lead", label_nor: "Bly", index: 77 },
  { label: "Metal|Pewter", label_nor: "Tinn", index: 78 },
  { label: "Ceramics & Porcelain|Earthenware", label_nor: "Leire", index: 79 },
  {
    label: "Ceramics & Porcelain|Stoneware",
    label_nor: "Steingods",
    index: 80,
  },
  { label: "Ceramics & Porcelain|Porcelain", label_nor: "Porselen", index: 81 },
  {
    label: "Ceramics & Porcelain|Terracotta",
    label_nor: "Terrakotta",
    index: 82,
  },
  {
    label: "Ceramics & Porcelain|Vitreous China",
    label_nor: "Porselen",
    index: 83,
  },
  {
    label: "Ceramics & Porcelain|Bone China",
    label_nor: "Benporselen",
    index: 84,
  },
  { label: "Ceramics & Porcelain|Glazed", label_nor: "Glasuret", index: 85 },
  { label: "Ceramics & Porcelain|Matte", label_nor: "Matt", index: 86 },
  { label: "Ceramics & Porcelain|Polished", label_nor: "Polert", index: 87 },
  {
    label: "Ceramics & Porcelain|Textured",
    label_nor: "Teksturert",
    index: 88,
  },
  { label: "Ceramics & Porcelain|Mosaic", label_nor: "Mosaikk", index: 89 },
  {
    label: "Ceramics & Porcelain|Hand-painted",
    label_nor: "Håndmalt",
    index: 90,
  },
  { label: "Ceramics & Porcelain|Crackle", label_nor: "Sprukket", index: 91 },
  { label: "Ceramics & Porcelain|Metallic", label_nor: "Metallisk", index: 92 },
  { label: "Ceramics & Porcelain|Luster", label_nor: "Luster", index: 93 },
  { label: "Plastics & Polymers|Acrylic", label_nor: "Akryl", index: 94 },
  {
    label: "Plastics & Polymers|Polyethylene",
    label_nor: "Polyetylen",
    index: 95,
  },
  { label: "Plastics & Polymers|PVC", label_nor: "PVC", index: 96 },
  {
    label: "Plastics & Polymers|Polycarbonate",
    label_nor: "Polykarbonat",
    index: 97,
  },
  {
    label: "Plastics & Polymers|Polypropylene",
    label_nor: "Polypropylen",
    index: 98,
  },
  { label: "Plastics & Polymers|ABS", label_nor: "ABS", index: 99 },
  { label: "Plastics & Polymers|Epoxy", label_nor: "Epoxy", index: 100 },
  { label: "Plastics & Polymers|Silicone", label_nor: "Silikon", index: 101 },
  { label: "Plastics & Polymers|Vinyl", label_nor: "Vinyl", index: 102 },
  { label: "Plastics & Polymers|Melamine", label_nor: "Melamin", index: 103 },
  { label: "Plastics & Polymers|Phenolic", label_nor: "Fenol", index: 104 },
  { label: "Plastics & Polymers|Urethane", label_nor: "Uretan", index: 105 },
  {
    label: "Plastics & Polymers|Fiberglass",
    label_nor: "Glassfiber",
    index: 106,
  },
  { label: "Plastics & Polymers|Nylon", label_nor: "Nylon", index: 107 },
  { label: "Plastics & Polymers|Acetate", label_nor: "Acetat", index: 108 },
  { label: "Paper & Cardboard|Wallpaper", label_nor: "Tapet", index: 109 },
  { label: "Paper & Cardboard|Cardboard", label_nor: "Papp", index: 110 },
  { label: "Paper & Cardboard|Kraft", label_nor: "Kraft", index: 111 },
  { label: "Paper & Cardboard|Parchment", label_nor: "Pergament", index: 112 },
  {
    label: "Paper & Cardboard|Foil embossed",
    label_nor: "Foliepreg",
    index: 113,
  },
  { label: "Paper & Cardboard|Textured", label_nor: "Strukturert", index: 114 },
  {
    label: "Paper & Cardboard|Handmade paper",
    label_nor: "Håndlaget papir",
    index: 115,
  },
  {
    label: "Paper & Cardboard|Recycled paper",
    label_nor: "Resirkulert papir",
    index: 116,
  },
  {
    label: "Paper & Cardboard|Flocked paper",
    label_nor: "Flockpapir",
    index: 117,
  },
  {
    label: "Paper & Cardboard|Metallic paper",
    label_nor: "Metallisk papir",
    index: 118,
  },
  {
    label: "Paper & Cardboard|Vellum",
    label_nor: "Pergamentpapir",
    index: 119,
  },
  {
    label: "Paper & Cardboard|Linen paper",
    label_nor: "Linnedpapir",
    index: 120,
  },
  {
    label: "Paper & Cardboard|Watercolor paper",
    label_nor: "Akvarellpapir",
    index: 121,
  },
  { label: "Paper & Cardboard|Matte", label_nor: "Matt", index: 122 },
  { label: "Paper & Cardboard|Glossy", label_nor: "Blank", index: 123 },
  { label: "Natural Fibers|Jute", label_nor: "Jute", index: 124 },
  { label: "Natural Fibers|Hemp", label_nor: "Hamp", index: 125 },
  { label: "Natural Fibers|Sisal", label_nor: "Sisal", index: 126 },
  { label: "Natural Fibers|Coir", label_nor: "Kokosfiber", index: 127 },
  {
    label: "Natural Fibers|Bamboo fiber",
    label_nor: "Bambusfiber",
    index: 128,
  },
  { label: "Natural Fibers|Wool", label_nor: "Ull", index: 129 },
  { label: "Natural Fibers|Cotton", label_nor: "Bomull", index: 130 },
  { label: "Natural Fibers|Linen", label_nor: "Linnen", index: 131 },
  { label: "Natural Fibers|Silk", label_nor: "Silke", index: 132 },
  { label: "Natural Fibers|Cashmere", label_nor: "Kasjmir", index: 133 },
  { label: "Natural Fibers|Mohair", label_nor: "Mohair", index: 134 },
  { label: "Natural Fibers|Alpaca", label_nor: "Alpakka", index: 135 },
  { label: "Natural Fibers|Seagrass", label_nor: "Sjøgress", index: 136 },
  { label: "Natural Fibers|Ramie", label_nor: "Ramie", index: 137 },
  { label: "Natural Fibers|Abaca", label_nor: "Abaka", index: 138 },
  { label: "Composite Materials|Concrete", label_nor: "Betong", index: 139 },
  { label: "Composite Materials|Plywood", label_nor: "Kryssfiner", index: 140 },
  { label: "Composite Materials|MDF", label_nor: "MDF", index: 141 },
  {
    label: "Composite Materials|Particleboard",
    label_nor: "Spaanplate",
    index: 142,
  },
  {
    label: "Composite Materials|Fiberglass",
    label_nor: "Glassfiber",
    index: 143,
  },
  { label: "Composite Materials|Corian", label_nor: "Corian", index: 144 },
  {
    label: "Composite Materials|Quartz composite",
    label_nor: "Kvartskompositt",
    index: 145,
  },
  { label: "Composite Materials|Terrazzo", label_nor: "Terrazzo", index: 146 },
  {
    label: "Composite Materials|Engineered stone",
    label_nor: "Ingeniørstein",
    index: 147,
  },
  { label: "Composite Materials|Laminate", label_nor: "Laminat", index: 148 },
  {
    label: "Composite Materials|Vinyl composite tile",
    label_nor: "Vinylfliser",
    index: 149,
  },
  { label: "Composite Materials|Resin", label_nor: "Harpet", index: 150 },
  {
    label: "Composite Materials|Acrylic composite",
    label_nor: "Akrylkompositt",
    index: 151,
  },
  {
    label: "Composite Materials|Carbon fiber",
    label_nor: "Karbonsfiber",
    index: 152,
  },
  {
    label: "Composite Materials|Glass fiber reinforced concrete",
    label_nor: "Glassfiberarmert betong",
    index: 153,
  },
];
export const materialExteriorObjects = [
  { label: "Facade|Brick", label_nor: "Murstein", index: 1 },
  { label: "Facade|Natural Stone", label_nor: "Naturstein", index: 2 },
  { label: "Facade|Granite", label_nor: "Granitt", index: 3 },
  { label: "Facade|Fieldstone", label_nor: "Fieldstone", index: 4 },
  { label: "Facade|Limestone", label_nor: "Kalkstein", index: 5 },
  { label: "Facade|Sandstone", label_nor: "Sandstein", index: 6 },
  { label: "Facade|Stucco", label_nor: "Stukk", index: 7 },
  { label: "Facade|Wood", label_nor: "Tre", index: 8 },
  { label: "Facade|Vinyl", label_nor: "Vinyl", index: 9 },
  { label: "Facade|Fiber cement", label_nor: "Fibersement", index: 10 },
  { label: "Facade|Metal", label_nor: "Metall", index: 11 },
  { label: "Facade|Glass", label_nor: "Glass", index: 12 },
  { label: "Roofing|Asphalt shingles", label_nor: "Asfaltshingel", index: 13 },
  { label: "Roofing|Metal roofing", label_nor: "Metalltak", index: 14 },
  { label: "Roofing|Clay tiles", label_nor: "Leirtak", index: 15 },
  { label: "Roofing|Concrete tiles", label_nor: "Betongtak", index: 16 },
  { label: "Roofing|Slate tiles", label_nor: "Skiferfliser", index: 17 },
  {
    label: "Roofing|Wooden shakes or shingles",
    label_nor: "Tre rist eller singel",
    index: 18,
  },
  { label: "Roofing|Solar tiles", label_nor: "Solcellefliser", index: 19 },
  { label: "Roofing|Roof tiles", label_nor: "Takstein", index: 20 },
  {
    label: "Decking and Walkways|Composite decking",
    label_nor: "Kompositt terrassebord",
    index: 21,
  },
  {
    label: "Decking and Walkways|Natural stone pavers",
    label_nor: "Natursteinsheller",
    index: 22,
  },
  {
    label: "Decking and Walkways|Concrete pavers",
    label_nor: "Betonnheller",
    index: 23,
  },
  {
    label: "Decking and Walkways|Brick pavers",
    label_nor: "Mursteins heller",
    index: 24,
  },
  {
    label: "Decking and Walkways|Treated lumber",
    label_nor: "Behandlet trevirke",
    index: 25,
  },
  { label: "Decking and Walkways|Teak", label_nor: "Teak", index: 26 },
  {
    label: "Decking and Walkways|Redwood decking",
    label_nor: "Redwood terrassebord",
    index: 27,
  },
  {
    label: "Decking and Walkways|Pea gravel",
    label_nor: "Ertegrus",
    index: 28,
  },
  {
    label: "Decking and Walkways|Anthracite Grey Deck",
    label_nor: "Antracite Grey dekk",
    index: 29,
  },
  {
    label: "Decking and Walkways|Crushed Granite",
    label_nor: "Knust granitt",
    index: 30,
  },
  {
    label: "Outdoor Fixtures and Accents|Wrought iron",
    label_nor: "Smedjern",
    index: 31,
  },
  {
    label: "Outdoor Fixtures and Accents|Stainless steel",
    label_nor: "Rustfritt stål",
    index: 32,
  },
  {
    label: "Outdoor Fixtures and Accents|Copper",
    label_nor: "Kobber",
    index: 33,
  },
  {
    label: "Outdoor Fixtures and Accents|Natural stone",
    label_nor: "Naturstein",
    index: 34,
  },
  {
    label: "Outdoor Fixtures and Accents|Ceramic tiles",
    label_nor: "Keramikkfliser",
    index: 35,
  },
];
export const materialGardenObjects = [
  { label: "Ground Cover Materials|Mulch", label_nor: "Bark", index: 1 },
  {
    label: "Ground Cover Materials|Wood Chips",
    label_nor: "Treflis",
    index: 2,
  },
  { label: "Ground Cover Materials|Grass", label_nor: "Gress", index: 3 },
  { label: "Ground Cover Materials|Clover", label_nor: "Kløver", index: 4 },
  { label: "Ground Cover Materials|Moss", label_nor: "Mose", index: 5 },
  { label: "Ground Cover Materials|Lawn", label_nor: "Plen", index: 6 },
  { label: "Ground Cover Materials|Pebbles", label_nor: "Småstein", index: 7 },
  {
    label: "Ground Cover Materials|River Rocks",
    label_nor: "Elvestein",
    index: 8,
  },
  {
    label: "Ground Cover Materials|Ground Cover Plants",
    label_nor: "Bakkeplanter",
    index: 9,
  },
  { label: "Ground Cover Materials|Thyme", label_nor: "Timian", index: 10 },
  { label: "Ground Cover Materials|Sedum", label_nor: "Sedum", index: 11 },
  {
    label: "Ground Cover Materials|Artificial Grass",
    label_nor: "Kunstgress",
    index: 12,
  },
  {
    label: "Ground Cover Materials|Decomposed Granite",
    label_nor: "Nedbrutt Granitt",
    index: 13,
  },
  {
    label: "Planters and Edging|Corten Steel",
    label_nor: "Cortenstål",
    index: 14,
  },
  { label: "Planters and Edging|Stone", label_nor: "Stein", index: 15 },
  {
    label: "Planters and Edging|Fruit Trees",
    label_nor: "Frukttrær",
    index: 16,
  },
  {
    label: "Planters and Edging|Berry Bushes",
    label_nor: "Bærbusker",
    index: 17,
  },
  { label: "Planters and Edging|Plants", label_nor: "Planter", index: 18 },
  { label: "Planters and Edging|Flowers", label_nor: "Blomster", index: 19 },
  { label: "Planters and Edging|Concrete", label_nor: "Betong", index: 20 },
  {
    label: "Planters and Edging|Natural Stone",
    label_nor: "Naturstein",
    index: 21,
  },
  {
    label: "Planters and Edging|Recycled Plastic",
    label_nor: "Resirkulert Plast",
    index: 22,
  },
  { label: "Planters and Edging|Bamboo", label_nor: "Bambus", index: 23 },
  { label: "Outdoor Furniture and Decor|Teak", label_nor: "Teak", index: 24 },
  {
    label: "Outdoor Furniture and Decor|Wrought Iron",
    label_nor: "Smijern",
    index: 25,
  },
  {
    label: "Outdoor Furniture and Decor|Rattan",
    label_nor: "Rattan",
    index: 26,
  },
  {
    label: "Outdoor Furniture and Decor|Fire Pits",
    label_nor: "Bålpanner",
    index: 27,
  },
  {
    label: "Outdoor Furniture and Decor|Concrete",
    label_nor: "Betong",
    index: 28,
  },
  {
    label: "Outdoor Furniture and Decor|Ceramic",
    label_nor: "Keramikk",
    index: 29,
  },
  {
    label: "Outdoor Furniture and Decor|Brick",
    label_nor: "Murstein",
    index: 30,
  },
  {
    label: "Water Features and Structures|Natural Stone",
    label_nor: "Naturstein",
    index: 31,
  },
  {
    label: "Water Features and Structures|Fiberglass",
    label_nor: "Glassfiber",
    index: 32,
  },
  {
    label: "Water Features and Structures|Copper",
    label_nor: "Kobber",
    index: 33,
  },
  { label: "Water Features and Structures|Wood", label_nor: "Tre", index: 34 },
  {
    label: "Water Features and Structures|Glass",
    label_nor: "Glass",
    index: 35,
  },
  { label: "Lighting|LED lights", label_nor: "LED-lys", index: 36 },
  {
    label: "Lighting|Solar-powered lights",
    label_nor: "Solcelledrevne lys",
    index: 37,
  },
  {
    label: "Lighting|Low-voltage halogen",
    label_nor: "Halogen med lav spenning",
    index: 38,
  },
  { label: "Lighting|Fiber optics", label_nor: "Fiberoptikk", index: 39 },
];
