import { Typography, Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { TextSplitter } from 'components/TextSplitter';
import { TokenPurchase } from 'components/TokenPurchase';
import { SEO } from 'components/SEO';
import { useEffect } from 'react';
export const Credits = () => {
	const profile = useSelector((state) => state.global.profile);

	useEffect(() => {
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	}, []);
	return (
		<>
			<SEO
				title="LønnLyst - Våre fleksible prispakker"
				description="Her kan du kjøpe flere genereringer av bilder fra LønnLyst!"
				name="Lønnlyst - Våre fleksible prispakker"
				type="tool"
			/>
			<Typography
				mt="3rem"
				variant="h1" textAlign="center">
				Våre fleksible prispakker
			</Typography>
			<Box
				display="flex"
				flexDirection="column"
				justifyContent="center"
				alignItems="center"
				m="1rem 0rem"
				width="95%">
				{profile ? <TokenPurchase /> : <></>}
			</Box>
		</>
	);
};
