import {
	Typography,
	Box,
	List,
	ListItem,
	Paper,
	useTheme,
} from '@mui/material';

export const TextSplitter = ({ terms }) => {
	const theme = useTheme();
	const splitTerm = (term) => {
		const splitIndex = term.indexOf(':');
		return {
			heading: term.substring(0, splitIndex),
			text: term.substring(splitIndex + 1),
		};
	};

	return (
		<Paper
			square={false}
			elevation={24}
			style={{
				padding: "1rem",
				backgroundColor: `${theme.palette.grey[5000]}`,
			}}>
			{terms.map((term, index) => {
				const { heading, text } = splitTerm(term);
				return (
					<Box
						key={index}
						style={{
							marginBottom: '10px',
							alignItems: 'flex-start',
						}}>
						<Typography
							component="span"
							variant="h4"
							color={theme.palette.grey[500]}
							style={{ fontWeight: 'bold', marginRight: '4px' }}>
							{heading}
						</Typography>
						<Typography
							color={theme.palette.grey[500]}
							variant="body1">
							{text}
						</Typography>
					</Box>
				);
			})}
		</Paper>
	);
};
export const TextSplitterNumbered = ({ terms }) => {
	const theme = useTheme();
	const splitTerm = (term) => {
		const splitIndex = term.indexOf(':');
		return {
			heading: term.substring(0, splitIndex),
			text: term.substring(splitIndex + 1),
		};
	};

	return (
		<Paper
			square={false}
			elevation={24}
			style={{
				padding: "1rem",
				backgroundColor: `${theme.palette.grey[5000]}`,
			}}>
			<List>
				{terms.map((term, index) => {
					const { heading, text } = splitTerm(term);
					return (
						<ListItem
							key={index}
							style={{
								display: 'list-item',
								listStyleType: 'decimal',
								marginBottom: '10px',
								alignItems: 'flex-start',
							}}>
							<Typography
								component="span"
								variant="h4"
								color={theme.palette.grey[500]}
								style={{ fontWeight: 'bold', marginRight: '4px' }}>
								{heading}
							</Typography>
							<Typography
								color={theme.palette.grey[500]}
								variant="body1">
								{text}
							</Typography>
						</ListItem>
					);
				})}
			</List>
		</Paper>
	);
};
export const TextSplitterSimple = ({ title, terms }) => {
	const theme = useTheme();
	return (
		<Paper
			square={false}
			elevation={24}
			style={{
				padding: "1rem",
				backgroundColor: `${theme.palette.grey[5000]}`,
			}}>
			{terms.map((term) => {
				return <Typography variant="body1">- {term}</Typography>;
			})}
		</Paper>
	);
};
