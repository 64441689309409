import { TextField, InputAdornment, Box, useTheme } from '@mui/material';

export const CustomInputTextField = ({
  label,
  onChange,
  adornment,
  placeholder,
  multiline,
  key
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        gap: theme.spacing(2),
        margin: "0.5rem"
      }}
    >
      <TextField
        fullWidth
        key={key}
        label={label}
        multiline={multiline}
        onChange={onChange}
        placeholder={placeholder}
        variant="outlined"
        InputProps={{
          startAdornment: adornment && (
            <InputAdornment position="start">{adornment}</InputAdornment>
          ),
          style: {
            paddingRight: theme.spacing(2), 
          },
        }}
        InputLabelProps={{
          shrink: true,
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              color: theme.palette.grey[500],
              backgroundColor: theme.palette.grey[4000],
            },
            '&:hover fieldset': {
              borderColor: theme.palette.primary.main,
              color: theme.palette.grey[500]
            },
            '& input': {
              color: theme.palette.grey[500]
            },
            '& textarea': {
              color: 'black',
            },
            '& input::placeholder': {
              color: theme.palette.grey[500]
            },
            '& textarea::placeholder': {
              color: theme.palette.grey[500],
            },
            '&.Mui-focused fieldset': {
              borderColor: theme.palette.primary.main,
              color: theme.palette.grey[500]
            },
          },
          '& .MuiInputLabel-root': {
						color: theme.palette.grey[500]
          },
          '& .MuiInputAdornment-root': {
						color: theme.palette.grey[500]
          }
        }}
      />
    </Box>
  );
};