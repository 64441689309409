import { useTheme, Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setErrorMessage } from 'state';
import { CustomStack } from './CustomStack';
import { CustomButton } from './CustomButton';
import { Cancel } from '@mui/icons-material';

export const ErrorScreen = (navigateBack) => {
	const theme = useTheme();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const errorMessage = useSelector((state) => state.global.errorMessage);

	useEffect(() => {
			window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
		const sendFeedback = async () => {
			const headers = {
				'Content-Type': 'application/json',
			};
			const data = {
				title: 'Automatic Feedback LønnLyst',
				feedback: errorMessage,
			};
			console.log('Data: ' + JSON.stringify(data));
			const infoResponse = await fetch(
				`https://pixygon-server.onrender.com/aiusers/feedback`,
				{
					method: 'POST',
					headers: headers,
					body: JSON.stringify(data),
				}
			);
		};
		sendFeedback();
	}, []);
	const closeErrorMessage = () => {
		dispatch(setErrorMessage({ errorMessage: '' }));
		if (navigateBack) navigate('/');
	};

	return (
		<Box
			margin="auto"
			marginTop="6rem">
			<CustomStack>
				<Box
					width="90%"
					height="200px"
					sx={{
						backgroundColor: theme.palette.tertiary.main,
						color: theme.palette.text.tertiary,
						border: `1px solid ${'transparent'}`,
						borderRadius: `20px`,
						margin: theme.spacing(1),
						padding: '16px 16px',
						flexGrow: 1,
						textAlign: 'center',
					}}>
					<Typography mb="1rem">
						Ånei! Her gikk noe galt! Vi har fått feilmeldingen, og skal få
						funnet ut av problemet!
					</Typography>
					<CustomButton
						onClick={() => closeErrorMessage()}>
							<Cancel sx={{ color: theme.palette.primary }} />
						Tilbake
					</CustomButton>
				</Box>
			</CustomStack>
		</Box>
	);
};