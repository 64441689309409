import { useSelector, useDispatch } from 'react-redux';
import { setImage, removeImage } from 'state';
import { useTheme, Box, Typography } from '@mui/material';
import { FileUpload } from '@mui/icons-material';
import { CustomStack } from './CustomStack';
import { CustomButton } from './CustomButton';
import { Clear } from '@mui/icons-material';
import { Canvas } from './Canvas';
import { useState, useEffect } from 'react';

export const CameraCapture = ({showCanvas = false }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const image = useSelector((state) => state.global.image);
  const [errorMessage, setErrorMessage] = useState("");
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const handleChange = (e) => {
    setErrorMessage("");
    const img = e.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(img);
    reader.onloadend = (e) => {
      var imageCheck = new Image();
      imageCheck.src = e.target.result;
      imageCheck.onload = () => {
        setHeight(imageCheck.height);
        setWidth(imageCheck.width);
        if (imageCheck.height < 768 || imageCheck.width < 768) {
          setErrorMessage("Høyde og bredde må være over 768px!");
          return;
        }
        dispatch(
          setImage({
            image: URL.createObjectURL(img),
            actualImage: img,
          })
        );
      };
    };
  };
  const handleRemove = () => {
    dispatch(removeImage());
  };

  useEffect(() => {
    dispatch(removeImage());
  }, []);
  return (
    <Box width="90%">
      <CustomStack>
        {image ? (
          <Box width="100%"
            justifyContent="center"
            alignItems="center"
            display="flex"
            flexDirection="column"
            flexWrap="nowrap">
            {showCanvas ? (<Canvas image={image} width={width} height={height} />) : (<img
              alt="not found"
              width={'250px'}
              src={image}
            />)}

            <CustomButton
              onClick={() => handleRemove()}>
              <Clear sx={{ color: theme.palette.primary }} />
              Fjern
            </CustomButton>
          </Box>
        ) : (
          <Box maxWidth="450px">
            <div className="file-upload">
              <FileUpload />
              <Typography variant="h3" sx={{
                color: theme.palette.grey[500],
              }}>
                Last opp bilde!
              </Typography>
              <Typography variant="body" sx={{
                color: theme.palette.grey[500],
              }}>
                Minimum filstørrelse 768x768
              </Typography>
              <input
                type="file"
                name="myImage"
                accept="image/png, image/jpg, image/jpeg"
                onChange={handleChange}
              />
            </div>
            {errorMessage !== "" ? (<Typography variant="body1" sx={{
              color: theme.palette.grey[500],
            }}>
              {errorMessage}
            </Typography>) : (<></>)}
          </Box>
        )}
      </CustomStack>
    </Box>
  );
};
