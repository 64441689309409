import { Box, ButtonGroup, Button } from "@mui/material";
import React, { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ReactSketchCanvas } from "react-sketch-canvas";
import { SliderField } from "components/SliderField";
import { Redo, Undo, Clear, Brush, EditOff } from "@mui/icons-material/";
import { styled } from "@mui/material/styles";
import { setMaskImage, setClearImage } from "state";

export const Canvas = ({ image, width, height, useCanvas }) => {
  const dispatch = useDispatch();
  const canvasRef = useRef(null);

  const [pencilSize, setPencilSize] = useState(30);
  const [eraseMode, setEraseMode] = useState(false);

  const onSetPencilSize = (value) => {
    setPencilSize(value);
  };
  const ToggleButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.tertiary,
    textTransform: "none",
    padding: "8px 8px",
    flex: "auto",
  }));
  const getImage = () => {
    canvasRef.current
      .exportImage("png")
      .then((dataUrl) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement("canvas");
          canvas.style.display = "none";
          const ctx = canvas.getContext("2d");
          canvas.width = width;
          canvas.height = height;
          console.log("Canvas size: " + canvas.width + " : " + canvas.height);
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

          const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
          const data = imageData.data;

          // Loop through each pixel
          for (let i = 0; i < data.length; i += 4) {
            // Check if the pixel is transparent
            const alpha = data[i + 3];
            if (alpha === 0) {
              // Set the color to black for transparent pixels
              data[i] = 0; // Red
              data[i + 1] = 0; // Green
              data[i + 2] = 0; // Blue
              data[i + 3] = 255; // Alpha (fully opaque)
            } else {
              // Set the color to white for non-transparent pixels
              data[i] = 255; // Red
              data[i + 1] = 255; // Green
              data[i + 2] = 255; // Blue
              data[i + 3] = 255; // Alpha (fully opaque)
            }
          }

          // Put the modified image data back onto the scaled canvas
          ctx.putImageData(imageData, 0, 0);

          const scaledDataUrl = canvas.toDataURL("image/png");
          if (scaledDataUrl && scaledDataUrl !== "data:") {
            const blob = dataURLtoBlob(scaledDataUrl);
            const file = new File([blob], "scaled_image.png", {
              type: "image/png",
              width: width,
              height: height,
            });
            console.log("File size: " + width + " : " + height);
            dispatch(
              setMaskImage({
                maskImage: URL.createObjectURL(file),
                actualMaskImage: file,
              }),
            );
          } else {
            console.log("Scaled data URL is empty.");
          }
        };
        img.src = dataUrl;
      })
      .catch((e) => {
        console.log(e);
      });
  };
  function dataURLtoBlob(dataUrl) {
    const parts = dataUrl.split(";base64,");
    const contentType = parts[0].split(":")[1];
    const byteCharacters = atob(parts[1]);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
  }
  function scaleCanvasToFit(inputWidth, inputHeight, maxWidth, maxHeight) {
    let canvasWidth, canvasHeight;
    const aspectRatio = inputWidth / inputHeight;
    if (inputWidth > inputHeight) {
      canvasWidth = Math.min(inputWidth, maxWidth);
      canvasHeight = canvasWidth / aspectRatio;
      console.log("Landscape Input: " + inputWidth + " : " + inputHeight);
      console.log(
        "Landscape " + canvasWidth + ":" + canvasHeight + " - " + aspectRatio,
      );
    } else {
      canvasHeight = Math.min(inputHeight, maxHeight);
      canvasWidth = canvasHeight * aspectRatio;
      console.log("Portrait Input: " + inputWidth + " : " + inputHeight);
      console.log(
        "Portrait " + canvasWidth + ":" + canvasHeight + " - " + aspectRatio,
      );
    }

    // Return the scaled canvas dimensions
    return { setWidth: canvasWidth, setHeight: canvasHeight };
  }
  const { setWidth: canvasWidth, setHeight: canvasHeight } = scaleCanvasToFit(
    width,
    height,
    325,
    325,
  );
  return (
    <Box>
      <ReactSketchCanvas
        style={{
          border: "0.0625rem solid #9c9c9c",
          borderRadius: "0.25rem",
        }}
        ref={canvasRef}
        backgroundImage={image}
        width={canvasWidth}
        height={canvasHeight}
        strokeWidth={pencilSize}
        eraserWidth={pencilSize}
        strokeColor="#FF0000AA"
        exportWithBackgroundImage={false}
        onStroke={() => getImage()}
      />
      <ButtonGroup variant="contained" size="large" fullWidth>
        <ToggleButton
          onClick={() => {
            setEraseMode(!eraseMode);
            canvasRef.current?.eraseMode(!eraseMode);
          }}
        >
          {eraseMode ? <Brush /> : <EditOff />}
        </ToggleButton>
        <ToggleButton
          onClick={() => {
            canvasRef.current?.undo();
          }}
        >
          <Undo />
        </ToggleButton>
        <ToggleButton
          onClick={() => {
            canvasRef.current?.redo();
          }}
        >
          <Redo />
        </ToggleButton>
        <ToggleButton
          onClick={() => {
            canvasRef.current?.clearCanvas();
            dispatch(setClearImage());
          }}
        >
          <Clear />
        </ToggleButton>
      </ButtonGroup>
      <SliderField
        label="Pensel"
        min={1}
        max={64}
        defaultValue={pencilSize}
        marks={null}
        onChange={onSetPencilSize}
        showValue={false}
      />
    </Box>
  );
};
