import React, { useState, useEffect } from 'react';

const ImageSlider = () => {
	const [currentIndex, setCurrentIndex] = useState(0);

	// Array of image URLs
	const images = [
		'https://pixygontech.b-cdn.net/LLDemoImage_Minimalistisk.jpg',
		'https://pixygontech.b-cdn.net/LLDemoImage_Moderne.jpg',
		'https://pixygontech.b-cdn.net/LLDemoImage_Tradisjonelt.png',
		'https://pixygontech.b-cdn.net/LLDemoImage_Rustikk.png',
		'https://pixygontech.b-cdn.net/LLDemoImage_Barnerom.jpg',
	];

	// Array of image descriptions
	const imageDescriptions = [
		'Minimalistisk',
		'Moderne',
		'Tradisjonelt',
		'Rustikk',
		'Barnerom',
	];

	useEffect(() => {
		// Automatically switch to the next image every 3 seconds
		const interval = setInterval(() => {
			setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
		}, 3000);

		return () => clearInterval(interval); // Cleanup interval on unmount
	}, []);

	return (
		<div
			style={{
				position: 'relative',
				width: '100%',
				height: '300px',
				overflow: 'hidden',
			}}>
			{images.map((image, index) => (
				<img
					key={index}
					src={image}
					alt={`Image ${index + 1}`}
					style={{
						position: 'absolute',
						width: '100%',
						height: '100%',
						objectFit: 'cover',
						opacity: index === currentIndex ? 1 : 0,
						transition: 'opacity 1s ease-in-out',
					}}
				/>
			))}
			<div
				style={{
					position: 'absolute',
					top: '20px',
					left: '50%',
					transform: 'translateX(-50%)',
					textAlign: 'center',
					width: '80%',
					color: '#fff',
					fontSize: '28px',
					opacity: 1,
					transition: 'opacity 1s ease-in-out',
					textShadow: '1px 1px 2px #000',
				}}>
				{imageDescriptions[currentIndex]}
			</div>
		</div>
	);
};

export default ImageSlider;
