import { Box, FormControl, MenuItem, InputLabel, Select } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const DropdownSelect = ({
	item,
	current,
	handleChange,
	styleObjects,
}) => {
	const theme = useTheme();

	const sortObjects = () => {
		var sorted = styleObjects.slice();
		sorted.sort(function (a, b) {
			if (a.label_nor < b.label_nor) {
				return -1;
			}
			if (a.label_nor > b.label_nor) {
				return 1;
			}
			return 0;
		});
		return sorted;
	};
	return (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				alignContent: 'center',
				gap: theme.spacing(2),
				margin: '1rem 0rem',
				width: '90%',
				maxWidth: '450px',
			}}>
			<FormControl
				fullWidth
				variant="outlined"
				sx={{
					'& .MuiOutlinedInput-root': {
						'& fieldset': {
							borderColor: theme.palette.grey[500],
							backgroundColor: theme.palette.grey[4000],
						},
						'&:hover fieldset': {
							borderColor: theme.palette.primary.main,
							color: theme.palette.grey[500],
						},
						'&.Mui-focused fieldset': {
							borderColor: theme.palette.primary.main,
						},
					},
					'& .MuiInputLabel-root': {
						color: theme.palette.grey[500],
					},
					'& .MuiInputAdornment-root': {
						color: theme.palette.grey[500],
					},
				}}>
				<InputLabel
					id="demo-simple-select-helper-label"
					sx={{
						color: theme.palette.grey[500],
					}}>
					{item}
				</InputLabel>
				<Select
					labelId="demo-simple-select-helper-label"
					id="demo-simple-select-helper"
					value={current}
					label={item}
					onChange={handleChange}
					sx={{
						color: theme.palette.grey[500],
					}}>
					{sortObjects().map((style) => (
						<MenuItem
							color={theme.palette.grey[500]}
							value={style.index}
							sx={{
								color: theme.palette.grey[500],
							}}>
							{style.label_nor}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</Box>
	);
};
