import * as React from 'react';
import { useState, useEffect } from 'react';
import {
  useTheme,
  Box,
  Stack,
  Typography,
  Backdrop,
  Button,
  ButtonGroup,
  CircularProgress
} from '@mui/material';
import {
  SaveAs,
  Share,
  OpenInFull,
  Favorite,
  FavoriteBorder,
  Delete
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { favoriteGeneratedObject, deleteGeneratedObject } from './ApiActions';

export const GeneratedObject = ({ dataObject, index, refetch }) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [isFavorite, setIsFavorite] = React.useState(dataObject.favorite);
  const [isLoadingFavorite, setIsLoadingFavorite] = React.useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = React.useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleShare = async () => {
    try {
      const response = await fetch(
        'https://pixygon-server.onrender.com/aiusers/getImage?' +
        new URLSearchParams({
          url: dataObject.generatedImage,
        })
      );
      if (!response.ok) {
        throw new Error('Failed to fetch image');
      }
      const imageBlob = await response.blob();
      const data = {
        files: [
          new File([imageBlob], 'image.png', {
            type: imageBlob.type,
          }),
        ],
        title: 'Redesignet Interiør fra LønnLyst.no',
        text: 'Nytt interiør fra Lønnlyst!',
        url: 'https://www.lonnlyst.no',
      };

      if (!navigator.canShare(data)) {
        console.error("Can't share");
        return;
      }
      await navigator.share(data);
    } catch (err) {
      console.error('Error sharing:', err);
    }
  };
  const handleSave = async () => {
    try {
      const response = await fetch(
        'https://pixygon-server.onrender.com/aiusers/getImage?' +
        new URLSearchParams({
          url: image,
        })
      );
      if (!response.ok) {
        throw new Error('Failed to fetch image');
      }
      const imageBlob = await response.blob();
      saveAs(URL.createObjectURL(imageBlob), 'LonnlystBilde.png');
    } catch (err) {
      console.error('Error sharing:', err);
    }
    return;
  };
  const handleFavorite = async () => {
    if (isLoadingFavorite) return;
    setIsLoadingFavorite(true);
    setIsFavorite(!isFavorite);
    const response = await favoriteGeneratedObject(dataObject._id);
    console.log(response);
    setIsLoadingFavorite(false);
  };
  const handleDelete = async () => {
    if (isLoadingDelete) return;
    setIsLoadingDelete(true);
    console.log("Delete! " + dataObject._id);
    const response = await deleteGeneratedObject(dataObject._id);
    console.log(response);
    refetch();
    setIsLoadingDelete(false);
  }

  const ToggleButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.tertiary,
    textTransform: 'none',
    padding: '8px 8px',
    flex: 'auto',
  }));

  useEffect(() => {
    setIsFavorite(dataObject.favorite);
  }, []);
  return (
    <Box
      key={'generatedObject' + index}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      sx={{
        backgroundColor: theme.palette.grey[5000],
        color: `transparent`,
        border: `1px solid ${'transparent'}`,
        '&:hover': {
          backgroundColor: theme.palette.grey[5000],
          color: '#fff',
          textShadow: '1px 1px 2px #000',
          border: `1px solid ${theme.palette.primary.main}`, // Highlight border on hover
        },
        borderRadius: '20px',
        textTransform: 'none',
        margin: theme.spacing(1), // Add margin around buttons
        width: '90%',
        minWidth: '200px',
        maxWidth: '400px',
        height: '240px',
        justifyContent: 'left',
        alignItems: 'center',
        backgroundImage: `url(${dataObject.generatedImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}>
      {isHovering ? (
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          useFlexGap
          flexWrap="wrap"
          display="flex"
          justifyContent="space-between">
          <Stack
            direction="column"
            spacing={2}
            justifyContent="left"
            alignItems="flex-start"
            useFlexGap
            flexWrap="wrap"
            p="8px">
            <Typography>Type: {dataObject.keywords[0]}</Typography>
            <Typography>Rom: {dataObject.keywords[1]}</Typography>
            <Typography>Stil: {dataObject.keywords[2]}</Typography>
            <Typography>Ai-bruk: {dataObject.keywords[3]}</Typography>
          </Stack>
          <Stack
            direction="column"
            spacing={2}
            justifyContent="right"
            alignItems="flex-end"
            useFlexGap
            flexWrap="wrap"
            p="8px">
            <ButtonGroup
              orientation="vertical"
              variant="contained"
              size="medium">
              <ToggleButton onClick={handleOpen}>
                <OpenInFull />{' '}
              </ToggleButton>
              <ToggleButton onClick={handleFavorite}> {isLoadingFavorite ? <CircularProgress size={20} /> : (
                isFavorite ? <Favorite /> : <FavoriteBorder />
              )}
              </ToggleButton>
              <ToggleButton onClick={handleShare}>
                <Share />{' '}
              </ToggleButton>
              <ToggleButton onClick={handleSave}>
                <SaveAs />{' '}
              </ToggleButton>
              <ToggleButton onClick={handleDelete}> {isLoadingDelete ? <CircularProgress size={20} /> : <Delete />}
              </ToggleButton>
            </ButtonGroup>
          </Stack>
        </Stack>
      ) : (<></>)}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}>
        <img
          height="auto"
          width="90%"
          src={dataObject.generatedImage}
        />
      </Backdrop>
    </Box>
  );
};
