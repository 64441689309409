import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import { setReferalId } from 'state';

export const Referal = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [searchParams, setSearchParams] = useSearchParams();
	const referalId = useSelector((state) => state.global.referalId);

	const capture = async () => {
		const referal = searchParams.get('referal');
		dispatch(
			setReferalId({
				referalId: referal,
			})
		);
		console.log('referalId: ' + referal);
		navigate('/');
	};

	useEffect(() => {
		capture();
	}, []);

	return <></>;
};
