import {
	Box,
	Stack,
	Skeleton,
	useTheme,
	useMediaQuery,
	Typography,
} from '@mui/material';
import { startLogin } from './ApiActions';
import { CustomStack } from './CustomStack';
import { useState, useEffect } from 'react';
import ImageSlider from './ImageSlider';

export const SplashScreen = () => {
	const theme = useTheme();
	const isNonMobileScreens = useMediaQuery('(min-width: 1000px)');
	const [genCount, setGenCount] = useState(null);
	const getGenCount = async () => {
		const infoResponse = await fetch(
			`https://pixygon-server.onrender.com/aiusers/getGeneratedObjectsCount/Lonnlyst`,
			{
				method: 'GET',
				headers: { 'Content-Type': 'application/json' },
			}
		);
		setGenCount(await infoResponse.json());
	};
	useEffect(() => {
		getGenCount();
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	}, []);
	return isNonMobileScreens ? (
		<Stack
			mt="8rem"
			direction="row"
			justifyContent="center"
			alignItems="flex-start" // Center items vertically
			spacing={theme.spacing(2)}
			padding="0.2rem">
			<img
				src="https://pixygontech.b-cdn.net/LLDemoImage.png"
				alt="Demo"
				width="300px"
				height="auto"
			/>
			<CustomStack sx={{ alignItems: 'flex-start' }}>
				<Box ml="4rem">
					<img
						src="https://pixygontech.b-cdn.net/LLLogoWide.png"
						alt="Logo"
						width="300px"
						height="auto"
						style={{
							marginTop: '80px',
							marginBottom: '20px',
							marginRight: '50px',
						}}
					/>
					<Typography
						mb="1rem"
						textAlign="left"
						variant="h2">
						Ta bilde, velg stil og se dine egne rom forandre seg.
					</Typography>
					<Box onClick={startLogin}>
						<vipps-mobilepay-button
							type="button"
							brand="vipps"
							language="no"
							variant="primary"
							rounded="true"
							verb="login"
							stretched="false"
							branded="true"
							loading="false"
						/>
					</Box>
					<Typography
						textAlign="left"
						variant="h4">
						Oppdag ditt hjems skjulte potensiale. LønnLyst's AI viser veien til
						forvandling.
					</Typography>
					<Typography
						mb="1rem"
						textAlign="left"
						variant="h4">
						Logg inn og prøv gratis nå
					</Typography>
					{genCount ? (
						<Typography
							mb="1rem"
							textAlign="left"
							variant="h4">
							Lønnlyst-brukere har redesignet {genCount} hager, fasader og rom
							så langt denne sesongen!
						</Typography>
					) : (
						<Skeleton
							mb="1rem"
							variant="text"
							animation="wave"
							fontSize="1.5rem"
							sx={{ fontSize: '1rem' }}
						/>
					)}
				</Box>
			</CustomStack>
		</Stack>
	) : (
		<>
			<ImageSlider />
			<Box>
				<CustomStack>
					<Typography
						m="1rem"
						textAlign="center"
						variant="h3">
						Ta bilde, velg stil og se dine egne rom forandre seg.
					</Typography>
					<Typography
						textAlign="center"
						variant="h4">
						Oppdag ditt hjems skjulte potensiale. LønnLyst viser veien til
						forvandling.
					</Typography>
					<Typography
						mb="1rem"
						textAlign="center"
						variant="h4">
						Logg inn og prøv gratis nå
					</Typography>
					<Box onClick={startLogin}>
						<vipps-mobilepay-button
							type="button"
							brand="vipps"
							language="no"
							variant="primary"
							rounded="true"
							verb="login"
							stretched="false"
							branded="true"
							loading="false"
						/>
					</Box>
					{genCount ? (
						<Typography
							mb="1rem"
							mt="1rem"
							textAlign="center"
							variant="h4">
							Lønnlyst-brukere har redesignet {genCount} hager, fasader og rom
							så langt denne sesongen!
						</Typography>
					) : (
						<Typography
							mb="1rem"
							textAlign="center"
							variant="h4">
							{' '}
							...
						</Typography>
					)}
				</CustomStack>
			</Box>
		</>
	);
};
