import { useTheme, Typography, Skeleton, Box, FormControl, FormLabel, FormControlLabel, Checkbox } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setLogout } from 'state';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { GeneratedObject } from 'components/GeneratedObject';

export const MyImages = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dataObjects, setDataObjects] = useState(null);
  const [sortByLiked, setSortByLiked] = useState(false);
  const [fetchingObjects, setFetchingObjects] = useState(false);
  const profile = useSelector((state) => state.global.profile);
  const product = 'Lonnlyst';

  const startLogout = async () => {
    dispatch(setLogout({ token: null }));
    navigate('/');
  };
  const handleSortChange = (event) => {
    if (fetchingObjects) return;
    setSortByLiked(!sortByLiked);
    getGenerations();
  };
  const getGenerations = async () => {
    setFetchingObjects(true);
    var url = `https://pixygon-server.onrender.com/aiusers/getGeneratedObjects/${profile._id}/${product}`
    if (sortByLiked)
      url = `https://pixygon-server.onrender.com/aiusers/getLikedGeneratedObjects/${profile._id}/${product}`
    const infoResponse = await fetch(
      url,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      }
    );
    setDataObjects(await infoResponse.json());
    setFetchingObjects(false);
  };
  useEffect(() => {
    if (!profile) {
      startLogout();
    }
    getGenerations();
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);
  return (
    <>
      <Typography
        mt="3rem"
        variant="h1">
        Dine bilder
      </Typography>

      <Box
        mb="20px"
        sx={{
          backgroundColor: theme.palette.grey[5000],
          color: theme.palette.grey[500],
          border: `1px solid ${'transparent'}`,
          borderRadius: '20px',
          textTransform: 'none',
          margin: theme.spacing(1), // Add margin around buttons
          padding: '16px 16px', // Comfortable padding
          justifyContent: 'left',
          alignItems: 'center',
          width: '90%',
          maxWidth: '1000px',
          minWidth: '400øx',
        }}>
        <FormControl>
          <FormLabel id="demo-radio-buttons-group-label">
            Bare vis favoritter
          </FormLabel>
          <FormControlLabel
            control={
              <Checkbox
                checked={sortByLiked}
                onChange={handleSortChange}
              />
            }
            sx={{
              color: theme.palette.grey[500],
            }}
            label="Ja takk"
          />
        </FormControl>
      </Box>

      {dataObjects ? (
        <Grid
          container
          spacing={2}
          minHeight={200}
          display="flex"
          justifyContent="center"
          alignItems="center">
          {dataObjects.map((dataObject, index) => (
            <GeneratedObject
              dataObject={dataObject}
              index={index}
              refetch={() => { getGenerations() }}
            />
          ))}
        </Grid>
      ) : (
        <Grid
          container
          spacing={2}
          minHeight={200}
          display="flex"
          justifyContent="center"
          alignItems="center">
          <Skeleton
            variant="rounded"
            animation="wave"
          />
          <Skeleton
            variant="rounded"
            animation="wave"
          />
          <Skeleton
            variant="rounded"
            animation="wave"
          />
          <Skeleton
            variant="rounded"
            animation="wave"
          />
        </Grid>
      )}
    </>
  );
};
