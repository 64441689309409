import { useTheme, Box, Typography } from '@mui/material';
import { TextSplitter } from 'components/TextSplitter';
import { SEO } from 'components/SEO';
import { useEffect } from 'react';
import { CustomButton } from 'components/CustomButton';

export const Faq = () => {
	const theme = useTheme();
	const terms = [
		'Hva er LønnLyst?:LønnLyst er en kunstig intelligens tjeneste som lar deg ta bilder av dine rom og øyeblikkelig se hvordan de vil kunne se ut i ulike stilarter og interiørdesign. Med avansert AI-teknologi fjernes eksisterende interiør for å gi plass til nye, inspirerende forslag.',
		'Er mine bilder private?:Absolutt, dine bilder forblir helt private. Kun du har tilgang til dem, og de deles ikke med andre eller med noen hos LønnLyst. Orginalbildene du tar av dine egne rom, fasader eller hage lagres ikke, disse slettes umiddelbart.',
		'Hvor raskt lages et bilde?:Dine bilder genereres umiddelbart og er klare for visning i løpet av 10 - 30 sekunder.',
		'Kan jeg lagre de ferdige bildene?:Selvfølgelig kan du lagre bildene på din telefon eller datamaskin. Dette er ideelt for planleggingsfasen av oppussing, renovering, eller når du skal diskutere ideer med håndverkere.',
		'Hvor mye koster det å bruke LønnLyst?:De to første bildene er gratis. Deretter, fra kun 79 kr får du tilgang til å utføre fra 10 redesign av dine rom, eksteriør eller hage. Trenger du flere redesign? Kjøp enkelt ekstra når som helst og fortsett å transformere dine rom etter behov.',
		'Hvilke betalingsmetoder aksepterer LønnLyst?:Vi tilbyr en sikker og pålitelig betalingserfaring gjennom vår partner Vipps, slik at du enkelt kan betale for tjenesten.',
		'Hva skjer når jeg har brukt opp mine bildegenereringer?:Da kan du kjøpe nye ved bruk av Vipps. Dette lar deg bruke tjenesten fleksibelt, uten behov for et løpende abonnement.',
		'Jeg trenger support, hvor henvender jeg meg?:Start gjerne med å ta kontakt med oss på jo@pixygon.io.',
	];
	const DemoVideoObject = ({ title, url }) => {
		return (
			<Box
				display="flex"
				justifyContent="center"
				sx={{
					flexDirection: 'column',
					backgroundColor: theme.palette.grey[5000],
					color: theme.palette.grey[500],
					border: `1px solid ${'transparent'}`,
					borderRadius: '20px',
					margin: theme.spacing(1), // Add margin around buttons
					padding: '16px 16px', // Comfortable padding
					justifyContent: 'center',
					alignItems: 'center',
				}}>
				<Typography sx={{ color: theme.palette.grey[500] }}>
					{title}{' '}
				</Typography>
				<CustomButton
					onClick={() => {
						window.open(url, '_blank');
					}}>
					Se video
				</CustomButton>
			</Box>
		);
	};
	useEffect(() => {
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	}, []);
	return (
		<>
			<SEO
				title="LønnLyst - Ofte Stile Spørsmål"
				description="Alle dine spørsmål om LønnLyst, besvart!"
				name="Lønnlyst - Ofte Stilte Spørsmål"
				type="tool"
			/>
			<Typography
				mt="3rem"
				variant="h1">
				Ofte Stilte Spørsmål
			</Typography>
			<Box
				display="flex"
				flexDirection="column"
				justifyContent="center"
				m="1rem 0rem"
				width="95%">
				<Box
					display="flex"
					justifyContent="center"
					flexDirection="row"
					width="100%"
					m="2rem 0rem">
					<DemoVideoObject
						title="Interiør"
						url="https://youtu.be/FXDRK5NrYyk?si=BqAP_B-CKSwzgGtX"
					/>
					<DemoVideoObject
						title="Hage"
						url="https://youtu.be/KSc-jOPEFU0?si=3MFSPrszVM_K3bZq"
					/>
					<DemoVideoObject
						title="Eksteriør"
						url="https://youtu.be/-m1em4hWA0Y?si=vFL5Dt5B4V2PI5Nx"
					/>
				</Box>
				<TextSplitter terms={terms} />
			</Box>
		</>
	);
};
