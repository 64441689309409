import { useTheme, Button, Stack, ButtonGroup, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { setIsWriting, setIsFinished, removeImage, setPrompt } from 'state';
import { styled } from '@mui/material/styles';
import { GeneratedImage } from './GeneratedImage';
import { CustomButton } from './CustomButton';
import {
	Replay,
	ThumbUpOffAlt,
	ThumbUp,
	ThumbDownOffAlt,
	ThumbDown,
} from '@mui/icons-material';
import { useEffect, useState } from 'react';

const LikeButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.tertiary,
    '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.text.secondary,
    },
    borderRadius: theme.shape.borderRadius,
    textTransform: 'none',
    padding: '8px 16px',
}));

export const FinishedScreen = () => {
	const theme = useTheme();
	const dispatch = useDispatch();
	const finalizedImage = useSelector((state) => state.global.finalizedImage);
	const [hasVoted, setHasVoted] = useState(false);
	const [thumbsUp, setThumbsUp] = useState(false);

	const restartSpeech = async () => {
		dispatch(setIsWriting({ isWriting: false }));
		dispatch(setIsFinished({ isFinished: false }));
		dispatch(setPrompt({ prompt: '' }));
		dispatch(removeImage());
	};
	const rate = (thumbsUp) => {
		setHasVoted(true);
		setThumbsUp(thumbsUp);
	};
	const Item = styled(Button)(() => ({
		backgroundColor: theme.palette.primary.main,
		...theme.typography.body2,
		padding: theme.spacing(1),
		borderRadius: '30px',
		textAlign: 'center',
		color: theme.palette.text.secondary,
		gap: '3rem',
		variant: 'contained',
		width: '200px',
		height: '60px',
		p: '0.1rem 1.5rem',
	}));
	useEffect(() => {
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	}, []);
	return (
		<>
			<GeneratedImage image={finalizedImage} />
			<Stack
				mt="1rem"
				direction="row"
				spacing={2}
				justifyContent="center"
				alignItems="center"
				useFlexGap
				flexWrap="wrap">
				{hasVoted ? (
					<ButtonGroup
						variant="contained"
						size="large"
						fullWidth>
						<LikeButton>
							{thumbsUp ? (
								<Typography
									textAlign="center"
									variant="body1"
									sx={{
										color: theme.palette.grey[500],
									}}>
									Tusen takk!
								</Typography>
							) : (
								<ThumbDown sx={{ color: theme.palette.primary }} />
							)}
						</LikeButton>
						<LikeButton>
							{thumbsUp ? (
								<ThumbUp sx={{ color: theme.palette.primary }} />
							) : (
								<Typography
									textAlign="center"
									variant="body1"
									sx={{
										color: theme.palette.grey[500],
									}}>
									Tusen takk!
								</Typography>
							)}
						</LikeButton>
					</ButtonGroup>
				) : (
					<ButtonGroup
						variant="contained"
						size="large"
						fullWidth>
						<LikeButton onClick={() => rate(false)}>
							<ThumbDownOffAlt sx={{ color: theme.palette.primary }} />
						</LikeButton>
						<LikeButton onClick={() => rate(true)}>
							<ThumbUpOffAlt sx={{ color: theme.palette.primary }} />
						</LikeButton>
					</ButtonGroup>
				)}

				<CustomButton onClick={() => restartSpeech()}>
					<Replay sx={{ color: theme.palette.primary }} /> Start på nytt
				</CustomButton>
			</Stack>
		</>
	);
};
