import React, { useState, useEffect } from 'react';
import { Stack, Grid, Button, ButtonGroup, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

const ToggleButton = styled(Button)(({ theme, active, disabled }) => ({
  // Adjusted styles to fit the overall look and feel
  backgroundColor: active ? theme.palette.primary.main : 'transparent',
  color: active ? theme.palette.text.tertiary : theme.palette.grey[500],
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
  textTransform: 'none',
  padding: '8px 16px', // Comfortable padding
  disabled: disabled,
  flex: 'auto',
}));

export const ToggleButtonGrid = ({ onSelectionChange, values, labels }) => {
  const theme = useTheme();
  const [requestType, setRequestType] = useState(values[0]);
  const midPoint = Math.ceil(values.length / 2);

  /*
  useEffect(() => {
    onSelectionChange(requestType);
  }, [onSelectionChange, requestType]);
  */
  const handleSelectionChange = (type) => {
    setRequestType(type);
    onSelectionChange(type);
  };

  return (
    <Stack
      direction="row"
      spacing={theme.spacing(3)}
      justifyContent="center"
      alignItems="center"
      width="90%"
      maxWidth="450px"
      margin="1rem 0rem">
      <Grid
        container
        direction="column"
        spacing={1}>
        <Grid
          item
          container
          justifyContent="center"
          spacing={1}>
          <Grid item>
            <ButtonGroup variant="contained">
              {values.slice(0, midPoint).map((value, index) => (
                <ToggleButton
                  key={value}
                  theme={theme}
                  active={requestType === value}
                  onClick={() => handleSelectionChange(value)}
                  disabled={false}>
                  {labels[index]}
                </ToggleButton>
              ))}
            </ButtonGroup>
          </Grid>
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          spacing={1}>
          <Grid item>
            <ButtonGroup variant="contained">
              {values.slice(midPoint).map((value, index) => (
                <ToggleButton
                  key={value}
                  theme={theme}
                  active={requestType === value}
                  onClick={() => handleSelectionChange(value)}
                  disabled={false}>
                  {labels[index]}
                </ToggleButton>
              ))}
            </ButtonGroup>
          </Grid>
        </Grid>
      </Grid>
    </Stack>
  );
};
