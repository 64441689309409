import React, { useState, useEffect } from "react";
import {
  Box,
  useMediaQuery,
  useTheme,
  Button,
  Stack,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Link,
} from "@mui/material";
import { AccountCircle, ArrowDownward, AutoAwesome } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getUser, payCredits, addGeneratedObject } from "components/ApiActions";
import { ToggleButtons } from "components/ToggleButtons";
import { ToggleButtonGrid } from "./ToggleButtonGrid";
import {
  finalizeTransaction,
  setType,
  setPrompt,
  setProfile,
  setFinalizedImage,
  setIsWriting,
  setIsFinished,
  setErrorMessage,
  setIsAborted,
  setRoom,
  setRedesignMode,
  setColor,
  setMaterial,
  setMaterialType,
} from "state";
import { CameraCapture } from "components/CameraCapture";
import {
  beautifulRedesign,
  removeFurniture,
  changeColorTexture,
  decorStaging,
  creativeRedesign,
  newBeautifulRedesign,
  inpainting,
  sketchToRender,
} from "components/HomedesignApi";
import { SliderField } from "components/SliderField";
import { CustomInputTextField } from "components/CustomInputTextField";
import { DropdownSelect } from "components/DropdownSelect";
import {
  styleObjects,
  styleExteriorObjects,
  styleGardenObjects,
  roomObjects,
  roomExteriorObjects,
  roomGardenObjects,
  colorObjects,
  materialObjects,
  materialExteriorObjects,
  materialGardenObjects,
} from "components/Styles";
import { setDesignStyle } from "state";
import { CustomButton } from "./CustomButton";
import ReactGA from "react-ga4";

export const GenerationScreen = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const type = useSelector((state) => state.global.type);
  const room = useSelector((state) => state.global.room);
  const designStyles = useSelector((state) => state.global.designStyles);
  const color = useSelector((state) => state.global.color);
  const material = useSelector((state) => state.global.material);
  const hasProfile = Boolean(
    useSelector((state) => state.global.profile) !== null,
  );
  const [imagesToGenerate, setImagesToGenerate] = useState(1);
  const [aiIntervention, setAiIntervention] = useState("Mid");
  const phoneNumber = useSelector((state) => state.global.phoneNumber);
  const user = useSelector((state) => state.global.user);
  const profile = useSelector((state) => state.global.profile);
  const image = useSelector((state) => state.global.image);
  const actualImage = useSelector((state) => state.global.actualImage);
  const errorMessage = useSelector((state) => state.global.errorMessage);
  const isAborted = useSelector((state) => state.global.isAborted);
  const redesignMode = useSelector((state) => state.global.redesignMode);
  const prompt = useSelector((state) => state.global.prompt);
  const hasDrawn = useSelector((state) => state.global.hasDrawn);
  const actualMaskImage = useSelector((state) => state.global.actualMaskImage);
  const maskedImage = useSelector((state) => state.global.maskImage);
  const testerMode = useSelector((state) => state.global.testerMode);
  const [isSticky, setIsSticky] = useState(false);

  const resetOptions = () => {
    dispatch(setRoom({ room: 1 }));
    dispatch(setIsAborted({ isAborted: false }));
    dispatch(setDesignStyle({ designStyles: getRandomStyle(type) }));
    dispatch(setMaterial({ material: 1 }));
  };
  const handleRequestTypeChange = (newType) => {
    dispatch(setType({ type: newType }));
    resetOptions();
  };
  const getRandomStyle = (newType) => {
    console.log("New type: " + newType);
    switch (newType) {
      case "Interior":
        return randomNumberInRange(0, styleObjects.length - 1);
      case "Exterior":
        return randomNumberInRange(0, styleExteriorObjects.length - 1);
      case "Garden":
        return randomNumberInRange(0, styleGardenObjects.length - 1);
    }
  };
  const handleRedesignChange = (value) => {
    dispatch(setRedesignMode({ redesignMode: value }));
    resetOptions();
  };
  const handleRoomChange = (event) => {
    dispatch(setRoom({ room: event.target.value }));
  };
  const handleStyleChange = (event) => {
    dispatch(setDesignStyle({ designStyles: event.target.value }));
  };
  const handleColorChange = (event) => {
    dispatch(setColor({ color: event.target.value }));
  };
  const handleMaterialChange = (event) => {
    console.log(event.target.value);
    dispatch(setMaterial({ material: event.target.value }));
    getMaterialType();
    getMaterial();
  };
  const setPromptText = (e) => {
    dispatch(
      setPrompt({
        prompt: e,
      }),
    );
  };
  const onSetImagesToGenerate = (value) => {
    setImagesToGenerate(value);
  };
  const onSetAiIntervention = (value) => {
    let s = "Mid";
    switch (value) {
      case 0:
        s = "Very Low";
        break;
      case 1:
        s = "Low";
        break;
      case 2:
        s = "Mid";
        break;
      case 3:
        s = "Extreme";
        break;
    }
    setAiIntervention(s);
  };
  const getRoom = () => {
    switch (type) {
      case "Interior":
        return roomObjects[room].label;
      case "Exterior":
        return roomExteriorObjects[room].label;
      case "Garden":
        return roomGardenObjects[room].label;
    }
  };
  const getStyle = () => {
    switch (type) {
      case "Interior":
        return styleObjects[designStyles].label;
      case "Exterior":
        return styleExteriorObjects[designStyles].label;
      case "Garden":
        return styleGardenObjects[designStyles].label;
    }
  };
  const getMaterialType = () => {
    switch (type) {
      case "Interior":
        console.log(
          "Get material: " +
            materialObjects[material].index +
            " : " +
            materialObjects[material].label,
        );
        return materialObjects[material].label.split("|")[0];
      case "Exterior":
        console.log(
          "Get material: " +
            materialExteriorObjects[material].index +
            " : " +
            materialExteriorObjects[material].label,
        );
        return materialExteriorObjects[material].label.split("|")[0];
      case "Garden":
        console.log(
          "Get material: " +
            materialGardenObjects[material].index +
            " : " +
            materialGardenObjects[material].label,
        );
        return materialGardenObjects[material].label.split("|")[0];
    }
  };
  const getMaterial = () => {
    switch (type) {
      case "Interior":
        return materialObjects[material].label.split("|")[1];
      case "Exterior":
        return materialExteriorObjects[material].label.split("|")[1];
      case "Garden":
        return materialGardenObjects[material].label.split("|")[1];
    }
  };
  const createRedesign = async () => {
    var profile = await getUser(user.sub);
    ReactGA.event({
      category: "Generation",
      action: "Started generation!",
    });
    dispatch(setProfile({ profile: profile }));
    dispatch(setIsAborted({ isAborted: false }));
    dispatch(setIsWriting({ isWriting: true }));
    dispatch(setIsFinished({ isFinished: false }));
    let returnedImage = null;
    switch (redesignMode) {
      case 0:
        returnedImage = await beautifulRedesign(
          actualImage,
          type,
          getRoom(),
          getStyle(),
          aiIntervention,
          imagesToGenerate,
          "",
          //`Translate from Norwegian: ${prompt}`
        );
        break;
      case 1:
        returnedImage = await creativeRedesign(
          actualImage,
          type,
          getRoom(),
          getStyle(),
          aiIntervention,
          imagesToGenerate,
          "",
          //`Translate from Norwegian: ${prompt}`,
          false,
        );
        break;
      case 2:
        if (actualMaskImage != null) {
          returnedImage = await inpainting(
            actualImage,
            actualMaskImage,
            type,
            imagesToGenerate,
            getStyle(),
            getRoom(),
            "",
            //`Translate from Norwegian: ${prompt}`
          );
        } else {
          returnedImage = await creativeRedesign(
            actualImage,
            type,
            getRoom(),
            getStyle(),
            aiIntervention,
            imagesToGenerate,
            "",
            //`Translate from Norwegian: ${prompt}`,
            true,
          );
        }
        break;
      case 3:
        returnedImage = await removeFurniture(
          actualImage,
          actualMaskImage,
          imagesToGenerate,
        );
        break;
      case 4:
        returnedImage = await changeColorTexture(
          actualImage,
          actualMaskImage,
          type,
          imagesToGenerate,
          "",
          //`Translate from Norwegian: ${prompt}`,
          colorObjects[color].label,
          "", //getMaterialType(),
          "", //getMaterial()
        );
        break;
      case 5:
        returnedImage = await decorStaging(
          actualImage,
          actualMaskImage,
          type,
          imagesToGenerate,
          getStyle(),
          getRoom(),
          "",
          //`Translate from Norwegian: ${prompt}`,
          false,
        );
        break;
      case 7:
        returnedImage = await changeColorTexture(
          actualImage,
          actualMaskImage,
          type,
          imagesToGenerate,
          "",
          //`Translate from Norwegian: ${prompt}`,
          "null",
          getMaterialType(),
          getMaterial(),
        );
        break;
      case 8:
        returnedImage = await sketchToRender(
          actualImage,
          type,
          getRoom(),
          getStyle(),
          aiIntervention,
          imagesToGenerate,
          "",
          //`Translate from Norwegian: ${prompt}`
        );
        break;
    }
    if (isAborted) {
      ReactGA.event({
        category: "Generation",
        action: "Abort generation!",
      });
      //dispatch(setIsAborted({ isAborted: false }));
      setIsAborted(false);
      dispatch(setIsWriting({ isWriting: false }));
      dispatch(setIsFinished({ isFinished: false }));
    } else {
      console.log(returnedImage);
      if (returnedImage.error) {
        ReactGA.event({
          category: "Generation",
          action: "Failed generation!",
        });
        console.log("Error!");
        dispatch(
          setErrorMessage({
            errorMessage:
              "Type: " +
              type +
              "\nRoom: " +
              getRoom() +
              "\nStyles: " +
              getStyle() +
              "\nAI: " +
              aiIntervention +
              "\nImages: " +
              imagesToGenerate +
              "\nPrompt: " +
              prompt +
              "\nError: " +
              JSON.stringify(returnedImage.error),
          }),
        );
        dispatch(setIsAborted({ isAborted: false }));
        dispatch(setIsWriting({ isWriting: false }));
        dispatch(setIsFinished({ isFinished: false }));
      } else {
        var payment = await payCredits(user.sub, 5);
        if (payment.message != "success!") {
          console.log(payment);
          return;
        }
        console.log(returnedImage);
        let response = null;
        switch (redesignMode) {
          case 0:
            response = await finalizeTextTransaction(
              [type, getRoom(), getStyle(), aiIntervention, prompt],
              "",
              returnedImage.success.generated_image[0],
            );
            break;
          case 1:
            response = await finalizeTextTransaction(
              [type, getRoom(), getStyle(), aiIntervention, prompt],
              "",
              returnedImage.success.generated_image[0],
            );
            break;
          case 2:
            response = await finalizeTextTransaction(
              [type, getRoom(), getStyle(), aiIntervention, prompt],
              "",
              returnedImage.success.generated_image[0],
            );
            break;
          case 3:
            response = await finalizeTextTransaction(
              [type, "", "", aiIntervention, prompt],
              "",
              returnedImage.success.generated_image[0],
            );
            break;
          case 4:
            response = await finalizeTextTransaction(
              [type, getMaterialType(), getMaterial(), aiIntervention, prompt],
              "",
              returnedImage.success.generated_image[0],
            );
            break;
          case 5:
            response = await finalizeTextTransaction(
              [type, getRoom(), getStyle(), aiIntervention, prompt],
              "",
              returnedImage.success.generated_image[0],
            );
            break;
          case 6:
            response = await finalizeTextTransaction(
              [type, getRoom(), getStyle(), aiIntervention, prompt],
              "",
              returnedImage.success.generated_image[0],
            );
            break;
          case 7:
            response = await finalizeTextTransaction(
              [type, getMaterialType(), getMaterial(), aiIntervention, prompt],
              "",
              returnedImage.success.generated_image[0],
            );
            break;
          case 8:
            response = await finalizeTextTransaction(
              [type, getRoom(), getStyle(), aiIntervention, prompt],
              "",
              returnedImage.success.generated_image[0],
            );
            break;
        }
        dispatch(setFinalizedImage({ image: response }));
        dispatch(finalizeTransaction());
        dispatch(setIsAborted({ isAborted: false }));
        dispatch(setIsWriting({ isWriting: false }));
        dispatch(setIsFinished({ isFinished: true }));
        ReactGA.event({
          category: "Generation",
          action: "Completed generation!",
        });
      }
    }
  };
  const finalizeTextTransaction = async (p, s, i) => {
    var profile = await getUser(user.sub);
    dispatch(setProfile({ profile: profile }));
    const result = await addGeneratedObject(profile._id, 5, p, s, i);
    return result;
  };
  const randomNumberInRange = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };
  const verifyGeneration = () => {
    if (!image) return false;
    else {
      if (redesignMode == 3 && !hasDrawn) return false;
      if (redesignMode == 4 && !hasDrawn) return false;
      if (redesignMode == 5 && !hasDrawn) return false;
      if (redesignMode == 6 && !hasDrawn) return false;
      if (redesignMode == 7 && !hasDrawn) return false;
    }
    return true;
  };
  const getProfile = async () => {
    var profile = await getUser(user.sub);
    dispatch(setProfile({ profile: profile }));
  };
  const getRedesignDescription = () => {
    switch (redesignMode) {
      case 0:
        return "Gi rommet ditt en liten oppgradering! Se fornyelsen utfolde seg mens du leker med ulike stiler og ideer.";
      case 1:
        return "Kjempekreativ du er da, flinkisen.";
      case 2:
        return "Legg til litt ekstra sjarm! Tegn på bildet for å legge til møbler og dekorasjon, og la kreativiteten din flyte fritt.";
      case 3:
        return "Rydd plass til forandring! Fjern unødvendige elementer og skap et blankt lerret for nye ideer.";
      case 4:
        return "Legg til et snev av farge! Prøv forskjellige farger og teksturer ved å tegne over overflater, og gi rommet ditt et friskt pust.";
      case 5:
        return "Fjern alt rundt det du tegner!";
      case 6:
        return "Fyll inn hvor du vil ha stæsjet ditt!";
      case 7:
        return "Tegn over for nytt materiale!";
      case 8:
        return "Se dine kreative ideer ta form! Konverter enkle skisser til realistiske visualiseringer og bli inspirert av dine egne design.";
    }
  };
  const StyleDropdowns = () => {
    if (redesignMode == 3) {
    } else if (redesignMode == 4) {
      switch (type) {
        case "Interior":
          return (
            <DropdownSelect
              item="Farge"
              current={color}
              handleChange={handleColorChange}
              styleObjects={colorObjects}
            />
          );
        case "Exterior":
          return (
            <DropdownSelect
              item="Farge"
              current={color}
              handleChange={handleColorChange}
              styleObjects={colorObjects}
            />
          );
        case "Garden":
          return (
            <DropdownSelect
              item="Farge"
              current={color}
              handleChange={handleColorChange}
              styleObjects={colorObjects}
            />
          );
      }
    } else if (redesignMode == 7) {
      switch (type) {
        case "Interior":
          return (
            <DropdownSelect
              item="Materiale"
              current={material}
              handleChange={handleMaterialChange}
              styleObjects={materialObjects}
            />
          );
        case "Exterior":
          return (
            <DropdownSelect
              item="Materiale"
              current={material}
              handleChange={handleMaterialChange}
              styleObjects={materialExteriorObjects}
            />
          );
        case "Garden":
          return (
            <DropdownSelect
              item="Materiale"
              current={material}
              handleChange={handleMaterialChange}
              styleObjects={materialGardenObjects}
            />
          );
      }
    } else {
      switch (type) {
        case "Interior":
          return (
            <>
              <DropdownSelect
                item="Rom"
                current={room}
                handleChange={handleRoomChange}
                styleObjects={roomObjects}
              />
              <DropdownSelect
                item="Stil"
                current={designStyles}
                handleChange={handleStyleChange}
                styleObjects={styleObjects}
              />
            </>
          );
        case "Exterior":
          return (
            <>
              <DropdownSelect
                item="Rom"
                current={room}
                handleChange={handleRoomChange}
                styleObjects={roomExteriorObjects}
              />
              <DropdownSelect
                item="Stil"
                current={designStyles}
                handleChange={handleStyleChange}
                styleObjects={styleExteriorObjects}
              />
            </>
          );
        case "Garden":
          return (
            <>
              <DropdownSelect
                item="Rom"
                current={room}
                handleChange={handleRoomChange}
                styleObjects={roomGardenObjects}
              />
              <DropdownSelect
                item="Stil"
                current={designStyles}
                handleChange={handleStyleChange}
                styleObjects={styleGardenObjects}
              />
            </>
          );
      }
    }
  };
  const ExtraDesignOptions = () => {
    return (
      <Box
        fullWidth
        minWidth="300px"
        maxWidth="450px"
        width="90%"
        sx={{
          alignItems: "center",
          alignContent: "center",
          gap: theme.spacing(2),
          margin: "1rem 0rem",
        }}
      >
        <Accordion
          fullWidth
          sx={{
            backgroundColor: theme.palette.grey[5000],
            border: `1px solid ${"transparent"}`,
            "&:hover": {
              color: theme.palette.text.secondary,
              border: `1px solid ${theme.palette.primary.main}`,
            },
            borderRadius: "20px",
            padding: "4px 16px",
          }}
        >
          <AccordionSummary
            expandIcon={<ArrowDownward />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography variant="h3" textAlign="center">
              Avansert
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <SliderField
              label="Antall Bilder"
              min={1}
              max={4}
              defaultValue={1}
              marks={null}
              onChange={() => onSetImagesToGenerate}
            />
            <SliderField
              label="AI Kontroll"
              min={0}
              max={3}
              defaultValue={2}
              marks={[
                { value: 0, label: "Veldig lav" },
                { value: 1, label: "Lav" },
                { value: 2, label: "Medium" },
                { value: 3, label: "Høy" },
              ]}
              onChange={() => onSetAiIntervention}
            />
          </AccordionDetails>
        </Accordion>
      </Box>
    );
  };
  useEffect(() => {
    getProfile();
    dispatch(setRedesignMode({ redesignMode: 0 }));
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Box mt="3rem" width="90%" mb="3rem">
        <Typography
          textAlign="center"
          variant="h3"
          sx={{
            color: theme.palette.grey[500],
          }}
        >
          Ta bilde, velg stil og se dine egne rom forandre seg.
        </Typography>
      </Box>
      <CameraCapture
        showCanvas={
          redesignMode == 2 ||
          redesignMode == 3 ||
          redesignMode == 4 ||
          redesignMode == 5 ||
          redesignMode == 7
        }
      />
      {image ? (
        <>
          <ToggleButtons
            onSelectionChange={handleRedesignChange}
            values={[0, 2, 3, 4, 8]}
            labels={["Forny", "Fyll", "Tøm", "Farg", "Design"]}
          />
          <Typography
            variant="body1"
            textAlign="center"
            sx={{
              color: theme.palette.grey[500],
            }}
          >
            {getRedesignDescription()}
          </Typography>
          {redesignMode == 3 ? (
            <></>
          ) : (
            <>
              <ToggleButtons
                onSelectionChange={handleRequestTypeChange}
                values={["Interior", "Exterior", "Garden"]}
                labels={["Interiør", "Eksteriør", "Hage"]}
                width="90%"
              />
              <CustomInputTextField
                label="Fritekst"
                onChange={(value) => setPromptText(value)}
                adornment={<AccountCircle />}
                placeholder="Noe spesielt du ønsker deg?"
                tooltip="Har du noen ønsker?"
              />
            </>
          )}
          <StyleDropdowns />
        </>
      ) : (
        <Typography
          textAlign="center"
          variant="body1"
          m="1rem"
          width="90%"
          sx={{
            color: theme.palette.grey[500],
          }}
        >
          Først må du laste opp ett bilde av det du vil se forandret. Hvis du
          går <Link href="/faq"> hit </Link> så kan du få litt tips og
          inspirasjon for å ta bedre bilder.
        </Typography>
      )}
      {testerMode ? <ExtraDesignOptions /> : <></>}
      {hasProfile ? (
        <>
          <Typography
            sx={{
              color: theme.palette.grey[500],
            }}
          >
            Du har {Math.floor(profile.credits / 5)} genereringer igjen!
          </Typography>
          {profile.credits >= 5 ? (
            <CustomButton
              theme={theme}
              className="button"
              disabled={!verifyGeneration()}
              onClick={() => createRedesign()}
            >
              <AutoAwesome />
              Lag design
            </CustomButton>
          ) : (
            <Button
              onClick={() => {
                navigate("/priser");
              }}
            >
              Kjøp bilder
            </Button>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};
