import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setLogin, setUser, setProfile } from 'state';
import { getInfo, getUser } from 'components/ApiActions';

export const Callback = () => {
	const navigate = useNavigate();
    const dispatch = useDispatch();
	const referalId = useSelector((state) => state.global.referalId);
	useEffect(() => {
		const getCodeFromURL = () => {
			const params = new URLSearchParams(window.location.search);
			return params.get('code');
		};
		const code = getCodeFromURL();
		const startLogin = async (code) => {
			const loginResponse = await fetch(
				`https://pixygon-server.onrender.com/vipps/token-exhange?code=${encodeURIComponent(code)}&product=Lonnlyst`,
				{
					method: 'POST'
				}
			);
			var json = JSON.parse(await loginResponse.text());
			var response = await getInfo(json.response, referalId);
			var profile = await getUser(response.sub);
			dispatch(setLogin({ token:  json.response }));
			dispatch(setUser({ user:  response }));
			dispatch(setProfile({ profile:  profile }));
			navigate(`/`);
		};
		if (code) {
			startLogin(code);
		}
	}, []);
	return <Box>Loading...</Box>;
};