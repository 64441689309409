import {
  Box,
  useTheme,
  Typography,
  LinearProgress,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { capturePayment, addCredits, getUser } from 'components/ApiActions';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  updateTransaction,
  setProfile,
  setPromoCode,
  setErrorMessage,
} from 'state';
import { Edit } from '@mui/icons-material';
import { CustomStack } from 'components/CustomStack';
import { ErrorScreen } from 'components/ErrorScreen';
import { CustomButton } from 'components/CustomButton';

export const PaymentRecieved = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const idempotencyKey = useSelector((state) => state.global.idempotencyKey);
  const reference = useSelector((state) => state.global.reference);
  const product = useSelector((state) => state.global.product);
  const user = useSelector((state) => state.global.user);
  const promoCode = useSelector((state) => state.global.promoCode);
  const errorMessage = useSelector((state) => state.global.errorMessage);
  const [purchaseComplete, setPurchaseComplete] = useState(false);

  const sleep = async (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  const retryCapture = async (idempotencyKey, reference) => {
    console.log("Retrying...");
    await sleep(5000);
    capture(idempotencyKey, reference);
  }
  const capture = async (idempotencyKey, reference) => {
    console.log("Idempotency: " + idempotencyKey);
    console.log("Reference: " + reference);
    setPurchaseComplete(false);
		const useOffer = false;
    var response = await capturePayment({
      idempotencyKey,
      reference,
      product,
      useOffer,
    });
    if (response.status === 400) {
      console.log(response.title);
      if(response.title === "Cannot capture before reservation") {
        retryCapture(idempotencyKey, reference);
        return;
      } else {
        dispatch(
          setErrorMessage({
            errorMessage: response.title + "\n" + response.detail
          })
        );
      }
    }
    if(response.state === 'CREATED') {
      retryCapture(idempotencyKey, reference);
      return;
    } else if (response.state === 'AUTHORIZED') {
      console.log('This is current PromoCode: ' + promoCode);
      await addCredits(user.sub, product, promoCode, idempotencyKey, reference);
      dispatch(
        updateTransaction({
          idempotencyKey: idempotencyKey,
          reference: reference,
          paid: true,
        })
      );
      dispatch(setPromoCode({ promoCode: '' }));
      var profile = await getUser(user.sub);
      dispatch(setProfile({ profile: profile }));
      setPurchaseComplete(true);
    } else if(response.state === 'ABORTED') {
      dispatch(setErrorMessage({ errorMessage: "Betaling avbrutt!" }));
    } else if(response.state === 'TERMINATED') {
      dispatch(setErrorMessage({ errorMessage: "Betaling avbrutt!" }));
    } else {
      dispatch(setErrorMessage({ errorMessage: JSON.stringify(response) }));
    }
  };

  useEffect(() => {
    capture(idempotencyKey, reference);
  }, []);

  return (
    errorMessage !== '' ? (
      <ErrorScreen navigateBack={true} />
    ) : (
      purchaseComplete ? (
        <>
          <CustomStack>
            <Box
              mt="8rem"
              endIcon={<Edit />}
              loading
              loadingPosition="end"
              variant="contained" >
								<Typography
									textAlign="center"
									variant="body1"
									sx={{
										color: theme.palette.grey[500],
									}}>
                    Betaling motatt! Flere bilde-genereringer har blitt lagt til på kontoen din.
								</Typography>
              <CustomButton onClick={() => { navigate('/') }} >Tilbake</CustomButton>
            </Box >
          </CustomStack > </>
      ) : (
        <>
          <CustomStack>
            <Box
              mt="8rem"
              endIcon={<Edit />}
              loading
              loadingPosition="end"
              variant="contained" >
              <LinearProgress color="success" />
								<Typography
									textAlign="center"
									variant="body1"
									sx={{
										color: theme.palette.grey[500],
									}}>
                    Prosesserer betaling...
								</Typography>
            </Box >
          </CustomStack >
        </>
      )
    )
  )
};
