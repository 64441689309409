import {
	useTheme,
	Box,
	Button,
	Typography,
	Skeleton,
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormLabel,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
	setProfile,
	setLogout,
	setPartnerEmail,
	setPromoCode,
	setTesterMode,
} from 'state';
import { useNavigate } from 'react-router-dom';
import { TokenPurchase } from 'components/TokenPurchase';
import { addPartner, getUser, getPartners } from 'components/ApiActions';
import { useState, useEffect } from 'react';
import { CustomInputTextField } from 'components/CustomInputTextField';
import { AccountCircle, ArrowDownward, CopyAll } from '@mui/icons-material';
import { PartnerObject } from 'components/PartnerObject';
import { CustomStack } from 'components/CustomStack';
import { CustomButton } from 'components/CustomButton';
import * as React from 'react';

export const Profile = () => {
	const theme = useTheme();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [dataObjects, setDataObjects] = useState(null);
	const [partners, setPartners] = useState(null);
	const [genCount, setGenCount] = useState(null);
	const [userCount, setUserCount] = useState(null);
	const user = useSelector((state) => state.global.user);
	const profile = useSelector((state) => state.global.profile);
	const product = 'Lonnlyst';
	const referalCode = `https://www.lonnlyst.no/referal?referal=${profile._id}`;
	const partnerInput = useSelector((state) => state.global.partnerEmail);
	const promoCodeInput = useSelector((state) => state.global.promoCode);
	const testerMode = useSelector((state) => state.global.testerMode);

	const startLogout = async () => {
		dispatch(setLogout({ token: null }));
		navigate('/');
	};
	const refreshCredits = async () => {
		var updatedProfile = await getUser(user.sub);
		dispatch(setProfile({ profile: updatedProfile }));
	};
	const getGenerations = async () => {
		const infoResponse = await fetch(
			`https://pixygon-server.onrender.com/aiusers/getGeneratedObjects/${profile._id}/${product}`,
			{
				method: 'GET',
				headers: { 'Content-Type': 'application/json' },
			}
		);
		setDataObjects(await infoResponse.json());
	};
	const getPartners = async () => {
		const infoResponse = await fetch(
			`https://pixygon-server.onrender.com/aiusers/getPartners`,
			{
				method: 'GET',
				headers: { 'Content-Type': 'application/json' },
			}
		);
		setPartners(await infoResponse.json());
	};
	const getGenCount = async () => {
		const infoResponse = await fetch(
			`https://pixygon-server.onrender.com/aiusers/getGeneratedObjectsCount/Lonnlyst`,
			{
				method: 'GET',
				headers: { 'Content-Type': 'application/json' },
			}
		);
		setGenCount(await infoResponse.json());
	};
	const getUserCount = async () => {
		const infoResponse = await fetch(
			`https://pixygon-server.onrender.com/aiusers/getUserCount`,
			{
				method: 'GET',
				headers: { 'Content-Type': 'application/json' },
			}
		);
		setUserCount(await infoResponse.json());
	};
	const refreshStats = async () => {
		setGenCount(null);
		setUserCount(null);
		getGenCount();
		getUserCount();
	};
	const SetPartner = (e) => {
		dispatch(setPartnerEmail({ partnerEmail: e.target.value }));
	};
	const SetPromoterCode = (e) => {
		dispatch(setPromoCode({ promoCode: e.target.value }));
	};
	const addPromoter = async () => {
		await addPartner(partnerInput, promoCodeInput);
	};
	const Partnerlist = () => {
		return (
			<>
				<Box width="90%">
					<Typography
						sx={{ color: theme.palette.grey[500] }}
						variant="h2">
						Partnere
					</Typography>
				</Box>
				{partners ? (
					<CustomStack>
						{partners.map((partner, index) => (
							<PartnerObject
								partner={partner}
								index={index}
								refresh={() => getPartners}
							/>
						))}
					</CustomStack>
				) : (
					<CustomStack>
						<Skeleton
							variant="rounded"
							animation="wave"
							width="100%"
							height="80px"
						/>
						<Skeleton
							variant="rounded"
							animation="wave"
							width="100%"
							height="80px"
						/>
						<Skeleton
							variant="rounded"
							animation="wave"
							width="100%"
							height="80px"
						/>
						<Skeleton
							variant="rounded"
							animation="wave"
							width="100%"
							height="80px"
						/>
					</CustomStack>
				)}
			</>
		);
	};
	const PartnerPanel = () => {
		return (
			<Box
				sx={{
					backgroundColor: theme.palette.grey[5000],
					color: theme.palette.grey[500],
					border: `1px solid ${'transparent'}`,
					borderRadius: '20px',
					textTransform: 'none',
					margin: theme.spacing(1), // Add margin around buttons
					padding: '16px 16px', // Comfortable padding
					justifyContent: 'left',
					alignItems: 'center',
					width: '90%',
					maxWidth: '1000px',
					minWidth: '400øx',
				}}>
				<Typography
					sx={{
						color: theme.palette.grey[500],
					}}
					mt="1rem"
					variant="h2">
					Partner-panel
				</Typography>
				<Typography
					sx={{
						color: theme.palette.grey[500],
					}}
					variant="body1">
					Hvis folk bruker denne promokoden {profile.promoCode} når de kjøper
					mynter, får du {profile.partnerCut}kr for hvert bildesalg, og bruker
					får {profile.partnerBonus}% ekstra bilde(r)! Til nå har du solgt{' '}
					{profile.partnerTokensSold / 5} bilder! Dvs du har tjent opp{' '}
					{(profile.partnerTokensSold / 5) * profile.partnerCut}kr!
				</Typography>
			</Box>
		);
	};
	const DeveloperPanel = () => {
		return (
			<Box
				mb="20px"
				sx={{
					backgroundColor: theme.palette.grey[5000],
					color: theme.palette.grey[500],
					border: `1px solid ${'transparent'}`,
					borderRadius: '20px',
					textTransform: 'none',
					margin: theme.spacing(1), // Add margin around buttons
					padding: '16px 16px', // Comfortable padding
					justifyContent: 'left',
					alignItems: 'center',
					width: '90%',
					maxWidth: '1000px',
					minWidth: '400øx',
				}}>
				<FormControl>
					<FormLabel id="demo-radio-buttons-group-label">
						Galematias-modus
					</FormLabel>
					<FormControlLabel
						control={
							<Checkbox
								checked={testerMode}
								onChange={handleTesterModeChange}
							/>
						}
						sx={{
							color: theme.palette.grey[500],
						}}
						label="Ja takk"
					/>
				</FormControl>
				<Typography variant="body1">
					Galematias-modus er for de fryktløse, som liker å leve litt på kanten.
					Er det farlig? Så absolutt. Hva som helst kan skje hvis du har på
					galematias-modus, det kan bli feil, ting kan kræsje, og andre
					ulumskheter. Men er det gøy? Definitivt. (Det er bare utvikler-modus,
					slapp av)
				</Typography>
			</Box>
		);
	};
	const StatsPanel = () => {
		return (
			<Box
				sx={{
					backgroundColor: theme.palette.grey[5000],
					color: theme.palette.grey[500],
					border: `1px solid ${'transparent'}`,
					borderRadius: '20px',
					textTransform: 'none',
					margin: theme.spacing(1), // Add margin around buttons
					padding: '16px 16px', // Comfortable padding
					justifyContent: 'left',
					alignItems: 'center',
					width: '90%',
					maxWidth: '1000px',
					minWidth: '400øx',
				}}>
				{genCount ? (
					<Typography
						mb="1rem"
						textAlign="center"
						variant="h4">
						Vi har redesignet {genCount} hager, fasader og rom så langt denne
						sesongen!
					</Typography>
				) : (
					<Skeleton
						mb="1rem"
						variant="text"
						animation="wave"
						fontSize="1.5rem"
						sx={{ fontSize: '1rem' }}
					/>
				)}
				{userCount ? (
					<Typography
						mb="1rem"
						textAlign="center"
						variant="h4">
						Vi har {userCount} brukere! Yowza!
					</Typography>
				) : (
					<Skeleton
						mb="1rem"
						variant="text"
						animation="wave"
						fontSize="1.5rem"
						sx={{ fontSize: '1rem' }}
					/>
				)}
				<CustomButton
					onClick={() => {
						refreshStats();
					}}>
					Refresh
				</CustomButton>
			</Box>
		);
	};
	const handleTesterModeChange = (event) => {
		dispatch(setTesterMode({ testerMode: !testerMode }));
	};
	const FriendReferalPanel = () => {
		return (
			<Box
				sx={{
					backgroundColor: theme.palette.grey[5000],
					color: theme.palette.grey[500],
					border: `1px solid ${'transparent'}`,
					borderRadius: '20px',
					textTransform: 'none',
					margin: theme.spacing(1),
					padding: '16px 16px',
					justifyContent: 'center',
					alignItems: 'center',
					width: '90%',
					maxWidth: '1000px',
					minWidth: '400øx',
					display: 'flex',
					flexDirection: 'column',
				}}>
				<Typography
					sx={{
						color: theme.palette.grey[500],
					}}
					mt="1rem"
					variant="h2">
					Inviter en venn
				</Typography>
				<Typography
					variant="body1"
					textAlign="center">
					Inviter en venn til lønnlyst, så får dere begge 20% rabatt på neste
					kjøp, og 3 ekstra genereringer. Send din venn denne linken, og be dem
					logge inn med vipps!
				</Typography>
				<Box
					onClick={() => {
						navigator.clipboard.writeText(referalCode);
					}}
					sx={{
						padding: '16px',
						borderRadius: '20px',
						backgroundColor: theme.palette.grey[10],
					}}>
					<Typography
						variant="body1"
						textAlign="center"
						sx={{
							color: theme.palette.grey[500],
						}}>
						{referalCode}
					</Typography>
				</Box>
				{profile.invited && profile.invited.length != 0 ? (
					<Typography
						m="1rem"
						variant="body1"
						textAlign="center">
						{profile.invited.length == 1 ? (
							<>Du har invitert en venn!</>
						) : (
							<>Du har invitert {profile.invited.length} venner!</>
						)}
					</Typography>
				) : (
					<></>
				)}
			</Box>
		);
	};
	useEffect(() => {
		if (!profile) {
			startLogout();
		}
		getGenerations();
		if (profile.isAdmin) {
			getPartners();
			getGenCount();
			getUserCount();
		}
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	}, []);
	return (
		<>
			<Typography
				mt="3rem"
				variant="h1">
				Heisann {user.given_name}!
			</Typography>
			{profile.isAdmin ? (
				<Box
					sx={{
						width: '90%',
						maxWidth: '1000px',
						minWidth: '400øx',
					}}>
					<Accordion
						fullWidth
						sx={{
							backgroundColor: theme.palette.grey[5000],
							border: `1px solid ${'transparent'}`,
							'&:hover': {
								color: theme.palette.text.secondary,
								border: `1px solid ${theme.palette.primary.main}`,
							},
							borderRadius: '20px',
							padding: '16px 8px',
						}}>
						<AccordionSummary
							expandIcon={<ArrowDownward />}
							aria-controls="panel1-content"
							id="panel1-header"
							m="1rem">
							<Typography variant="h2">Admin-panel</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Box
								key="adminpanel"
								sx={{
									backgroundColor: theme.palette.grey[5000],
									color: theme.palette.grey[500],
									border: `1px solid ${'transparent'}`,
									borderRadius: '20px',
									textTransform: 'none',
									margin: theme.spacing(1), // Add margin around buttons
									padding: '16px 16px', // Comfortable padding
									justifyContent: 'left',
									alignItems: 'center',
								}}>
								<Typography variant="body1">
									Heisann sveisan. Ser ut som du er admin! Da er du garantert
									kul. Her har du litt verktøy for å gjøre folk til promoters!
								</Typography>

								<Typography
									sx={{
										color: theme.palette.grey[500],
									}}
									mt="1rem"
									variant="h2">
									Statistikk
								</Typography>
								<StatsPanel />

								<Typography
									sx={{
										color: theme.palette.grey[500],
									}}
									mt="1rem"
									variant="h2">
									Legg til ny partner
								</Typography>

								<CustomInputTextField
									key="promoterInput"
									label="Add Partner"
									onChange={SetPartner}
									adornment={<AccountCircle />}
									placeholder=""
									tooltip="Har du noen ønsker?"
								/>
								<CustomInputTextField
									key="promoCodeInput"
									label="Add Promo Code"
									onChange={SetPromoterCode}
									adornment={<AccountCircle />}
									placeholder=""
									tooltip="Har du noen ønsker?"
								/>
								<CustomButton
									theme={theme}
									onClick={() => addPromoter()}>
									Legg til partner
								</CustomButton>
								<Partnerlist />
							</Box>
						</AccordionDetails>
					</Accordion>
				</Box>
			) : (
				<></>
			)}
			{profile.isPartner ? <PartnerPanel /> : <></>}
			<FriendReferalPanel />
			<Button onClick={() => refreshCredits()}>
				<Typography variant="body">
					Du har {Math.floor(profile.credits / 5)} genereringer igjen!
				</Typography>
			</Button>
			<CustomButton
				theme={theme}
				onClick={() => startLogout()}>
				Logg ut
			</CustomButton>
			<TokenPurchase />
			<DeveloperPanel />
		</>
	);
};
