import ReactGA from 'react-ga4';

export const startLogin = async () => {
	ReactGA.event({
		category: 'Vipps',
		action: 'Vipps-login started!',
	});
	const loginResponse = await fetch(
		`https://pixygon-server.onrender.com/vipps/login?product=Lonnlyst`,
		{
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
		}
	);
	var response = await loginResponse.json();
	window.location.replace(response.url);
};
export const getInfo = async (token, referal) => {
	const infoResponse = await fetch(
		`https://pixygon-server.onrender.com/vipps/info?token=${encodeURIComponent(token)}&referal=${referal}`,
		{
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
		}
	);
	ReactGA.event({
		category: 'Vipps',
		action: 'Vipps-login completed!',
	});
	return await infoResponse.json();
};
export const getUser = async (id) => {
	const infoResponse = await fetch(
		`https://pixygon-server.onrender.com/aiusers/profile/${encodeURIComponent(id)}`,
		{
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
		}
	);
	return await infoResponse.json();
};
export const payCredits = async (id, amount) => {
	const infoResponse = await fetch(
		`https://pixygon-server.onrender.com/aiusers/useCredits/${encodeURIComponent(id)}/${amount}`,
		{
			method: 'PATCH',
			headers: {
				'Content-Type': 'application/json',
			},
		}
	);
	var response = await infoResponse.json();
	return response;
};
export const addCredits = async (
	id,
	amount,
	promoCode,
	idempotencyKey,
	reference
) => {
	var tokens = 0;
	switch (amount) {
		case '10 Mynter':
			tokens = 10;
			break;
		case '50 Mynter':
			tokens = 50;
			break;
		case '100 Mynter':
			tokens = 100;
			break;
		case '200 Mynter':
			tokens = 200;
			break;
		case '400 Mynter':
			tokens = 400;
			break;
	}
	if (promoCode == '') promoCode = 'None';
	const infoResponse = await fetch(
		`https://pixygon-server.onrender.com/aiusers/addCredits/${encodeURIComponent(id)}/${tokens}/${encodeURIComponent(promoCode)}/${encodeURIComponent(idempotencyKey)}/${encodeURIComponent(reference)}`,
		{
			method: 'PATCH',
			headers: {
				'Content-Type': 'application/json',
			},
		}
	);
	var response = await infoResponse.json();
	return response;
};
export const verifyPromoCode = async (promoCode) => {
	const infoResponse = await fetch(
		`https://pixygon-server.onrender.com/aiusers/verifyPromoCode/${encodeURIComponent(promoCode)}`, {
			method: 'GET',
			headers: { 'Content-Type': 'application/json', },
		}
	);
	var response = await infoResponse.json();
	if (response.message == 'success!') return true;
	else return false;
};
export const startPayment = async ({
	idempotencyKey,
	reference,
	phoneNumber,
	product,
	useOffer = false,
}) => {
	ReactGA.event({
		category: 'Vipps',
		action: 'Vipps-payment started!',
	});
	switch (product) {
		case '10 Mynter':
			product = 10;
			break;
		case '50 Mynter':
			product = 50;
			break;
		case '100 Mynter':
			product = 100;
			break;
		case '200 Mynter':
			product = 200;
			break;
		case '400 Mynter':
			product = 400;
			break;
	}
	console.log(idempotencyKey + ' || ' + reference);
	const infoResponse = await fetch(
		`https://pixygon-server.onrender.com/vipps/payment?uuid=${encodeURIComponent(idempotencyKey)}&reference=${reference}&number=${phoneNumber}&product=${encodeURIComponent(product)}&site=Lonnlyst&useOffer=${useOffer}`,
		{
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
		}
	);
	var response = await infoResponse.json();
	window.location.replace(response.redirectUrl);
	return response;
};
export const capturePayment = async ({
	idempotencyKey,
	reference,
	product,
	useOffer = false,
}) => {
	switch (product) {
		case '10 Mynter':
			product = 10;
			break;
		case '50 Mynter':
			product = 50;
			break;
		case '100 Mynter':
			product = 100;
			break;
		case '200 Mynter':
			product = 200;
			break;
		case '400 Mynter':
			product = 400;
			break;
	}
	const infoResponse = await fetch(
		`https://pixygon-server.onrender.com/vipps/capturePayment?uuid=${encodeURIComponent(idempotencyKey)}&reference=${reference}&product=${encodeURIComponent(product)}&useOffer=${useOffer}`,
		{
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
		}
	);
	ReactGA.event({
		category: 'Vipps',
		action: 'Vipps-payment captured!',
		value: product,
		revenue: product,
	});
	return await infoResponse.json();
};
export const addGeneratedObject = async (
	id,
	creditCost,
	keywords,
	generatedText,
	generatedImage
) => {
	const infoResponse = await fetch(
		`https://pixygon-server.onrender.com/aiusers/addGeneratedObject`,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				id: id,
				product: 'Lonnlyst',
				favorite: false,
				cost: creditCost,
				keywords: keywords,
				generatedText: generatedText,
				generatedImage: generatedImage,
			}),
		}
	);
	var response = await infoResponse.json();
	return response;
};
export const favoriteGeneratedObject = async (id) => {
	const infoResponse = await fetch(
		`https://pixygon-server.onrender.com/aiusers/favoriteGeneratedObject/${encodeURIComponent(id)}`,
		{
			method: 'PATCH',
			headers: {
				'Content-Type': 'application/json',
			},
		}
	);
	var response = await infoResponse.json();
	return response;
};
export const deleteGeneratedObject = async (id) => {
	const infoResponse = await fetch(
		`https://pixygon-server.onrender.com/aiusers/deleteGeneratedObject/${encodeURIComponent(id)}`,
		{
			method: 'PATCH',
			headers: {
				'Content-Type': 'application/json',
			},
		}
	);
	var response = await infoResponse.json();
	return response;
};
export const addPartner = async (email, promoCode) => {
	const infoResponse = await fetch(
		`https://pixygon-server.onrender.com/aiusers/addPartner/${email}/${promoCode}`,
		{
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
		}
	);
	return await infoResponse.json();
};
export const removePartner = async (email) => {
	const infoResponse = await fetch(
		`https://pixygon-server.onrender.com/aiusers/removePartner/${email}`,
		{
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
		}
	);
	return await infoResponse.json();
};
export const getPartners = async () => {
	const infoResponse = await fetch(
		`https://pixygon-server.onrender.com/aiusers/getPartners`,
		{
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
		}
	);
	return await infoResponse.json();
};