import { useTheme, Stack, Box, Typography } from '@mui/material';
import { CustomButton } from 'components/CustomButton';
import { CustomStack } from 'components/CustomStack';
import { useEffect } from 'react';

export const AboutUs = () => {
  const theme = useTheme();

  const Person = ({ name, title, email, image, linkedin }) => {
    return (
      <Box
        mt="5px"
        sx={{
          minWidth: '200px',
          maxWidth: '500px',
          width: '100%',
        }}>
        <Stack
          direction="row"
          alignItems="center"
          backgroundColor="#fff"
          borderRadius="20px">
          <Stack
            direction="column"
            spacing={2}
            justifyContent="left"
            alignItems="flex-start"
            useFlexGap
            flexWrap="wrap"
            p="8px">
            <Box
              width="150px"
              height="150px"
              borderRadius="100px"
              sx={{
                backgroundImage: `url(${image})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
            />
          </Stack>
          <Stack
            direction="column"
            spacing={2}
            justifyContent="right"
            alignItems="flex-start"
            useFlexGap
            flexWrap="wrap"
            p="8px">
            <Typography
              color={theme.palette.grey[500]}
              onClick={() => (window.open(linkedin, "_blank"))}
              variant="h4">
              {name}
            </Typography>
            <Typography
              color={theme.palette.grey[500]}
              variant="body1">
              {title}
            </Typography>
            <Typography
              color={theme.palette.grey[500]}
              variant="body1">
              {email}
            </Typography>
          </Stack>
        </Stack>
      </Box>
    );
  };
  const getFile = async () => {
    const infoResponse = await fetch(
      'https://pixygontech.b-cdn.net/LL_PressKit.zip',
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/zip' },
      }
    ).then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));

        const link = document.createElement('a');
        link.href = url;
        link.download = "Lønnlyst_Presskit.zip";
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  };
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);
  return (
    <>
      <Typography
        textAlign="center" mt="3rem"
        variant="h1">
        Om oss
      </Typography>
      <Box
        padding="8px"
        backgroundColor="#fff"
        borderRadius="20px"
        sx={{
          minWidth: '200px',
          maxWidth: '500px',
        }}>
        <CustomStack>
          <Typography
            variant="body1"
            color={theme.palette.grey[500]}>
            Lønnlyst er en webtjeneste utviklet av Pixygon.
          </Typography>
          <Box justifyContent="center" display="flex" backgroundColor={theme.palette.grey[500]} borderRadius="20px">
            <img p="8px" src="https://pixygontech.b-cdn.net/PixygonLogoWide.png" width="90%" onClick={() => (window.open("https://www.pixygon.io", "_blank"))} />
          </Box>
          <Typography
            variant="body1"
            mt="10px"
            color={theme.palette.grey[500]}>
            For henvendelser, kontakt oss gjerne på pixygon@pixygon.io
          </Typography>
        </CustomStack>
      </Box>

      <Box
        mt="20px"
        padding="8px"
        backgroundColor="#fff"
        borderRadius="20px"
        justifyContent="center"
        display="flex"
        sx={{
          flexDirection: 'column',
          minWidth: '200px',
          maxWidth: '500px',
        }}>
        <Typography
          mt="20px"
          textAlign="center"
          variant="h2">
          Presse
        </Typography>
        <Typography
          mt="20px"
          textAlign="center"
          variant="body1"
          color={theme.palette.grey[500]}>
          Last ned en .zip med logoer og annet materiell!
        </Typography>
        <CustomButton onClick={() => getFile()} > Last ned </CustomButton>

      </Box>
      <Typography
        mt="20px"
        textAlign="center"
        variant="h2">
        Teamet
      </Typography>
      <Person
        name="Jo Jørgen Stordal"
        title="Daglig Leder"
        email="jo@pixygon.io"
        image="https://pixygontech.b-cdn.net/1679692090087.jpg"
        linkedin="https://www.linkedin.com/in/jjs-60904236/"
      />
      <Person
        name="Anders Oneiros"
        title="Kodemannen"
        email="anders@pixygon.io"
        image="https://pixygontech.b-cdn.net/1669775093138.jpg"
        linkedin="https://www.linkedin.com/in/andershorne/"
      />
      <Person
        name="John Weaver"
        title="Grafikkmannen"
        email="john@pixygon.io"
        image="https://pixygontech.b-cdn.net/1671622964304.jpg"
        linkedin="https://www.linkedin.com/in/john-weaver-912841226/"
      />
    </>
  );
};
