import {
	useMediaQuery,
	useTheme,
	Button,
	Box,
	Stack,
	Typography,
	CircularProgress,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { setProduct, setTransaction, setPromoCode } from 'state';
import { useNavigate } from 'react-router-dom';
import { startPayment, verifyPromoCode } from 'components/ApiActions';
import { CustomInputTextField } from './CustomInputTextField';
import { AccountCircle } from '@mui/icons-material';
import { useState } from 'react';

export const TokenPurchase = () => {
	const theme = useTheme();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const isNonMobile = useMediaQuery('(min-width: 1000px)');
	const phoneNumber = useSelector((state) => state.global.phoneNumber);
	const user = useSelector((state) => state.global.user);
	const profile = useSelector((state) => state.global.profile);
	const [isVerified, setIsVerified] = useState(false);
	const [hasClicked, setHasClicked] = useState(false);

	const handleSelectionChange = (type) => {
		console.log('Get credits: ' + type);
	};
	const doPayment = (product) => {
		if (hasClicked) return;
		setHasClicked(true);
		const idempotencyKey = crypto.randomUUID();
		const reference = createString(50);
		dispatch(
			setTransaction({
				idempotencyKey: idempotencyKey,
				reference: reference,
			})
		);
		dispatch(setProduct({ product: product }));
		console.log('Set idempotency: ' + idempotencyKey);
		console.log('Set reference: ' + reference);
		const useOffer = false;
		startPayment({ idempotencyKey, reference, phoneNumber, product, useOffer });
	};
	const SetPromoterCode = (event) => {
		dispatch(setPromoCode({ promoCode: event.target.value }));
		startVerifyPromoCode(event.target.value);
	};
	const createString = (length) => {
		let result = '';
		const characters =
			'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-';
		const charactersLength = characters.length;
		let counter = 0;
		while (counter < length) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
			counter += 1;
		}
		return result;
	};
	const startVerifyPromoCode = async (code) => {
		setIsVerified(false);
		setIsVerified(await verifyPromoCode(code));
	};
	const TokenPurchaseBox = ({
		amount,
		price,
		type,
		packname,
		packText,
		target = '',
		hasOffer = false,
		offerValue = 0,
	}) => {
		return (
			<Box
				sx={{
					backgroundColor: theme.palette.grey[5000],
					color: theme.palette.grey[500],
					boxShadow: '0px 10px 8px #02020299',
					borderRadius: '20px',
					width: '350px',
					margin: theme.spacing(1), // Add margin around buttons
					padding: '8px', // Comfortable padding
					justifyContent: 'center',
					alignItems: 'center',
				}}>
				{/* hasOffer ? (
					<Box
						width="100%"
						height="300px"
						backgroundColor={theme.palette.primary.main}>
							<Typography textAlign="center" variant="h2" color={theme.palette.text.primary}>{offerValue}% off</Typography>
						</Box>
				) : (
					<></>
				) */}
				<Box
					flexDirection="column"
					gap=".6rem"
					display="flex"
					justifyContent="center"
					alignItems="center"
					m=".5rem 1rem">
					<Box
						width="100%"
						flexDirection="column"
						gap=".5rem"
						display="flex">
						<Box
							width="100%"
							flexDirection="row"
							justifyContent="space-between"
							display="flex">
							<Typography
								variant="h4"
								textAlign="left">
								{packname}
							</Typography>
							{target != '' ? (
								<Box
									p="0rem 0.5rem"
									borderRadius="10px"
									backgroundColor={theme.palette.primary.main}>
									<Typography
										textAlign="center"
										variant="body1"
										color={theme.palette.text.primary}>
										{target}
									</Typography>
								</Box>
							) : (
								<></>
							)}
						</Box>
						<Box
							width="100%"
							flexDirection="row"
							display="flex"
							justifyContent="flex-start"
							alignItems="baseline">
							{hasOffer ? (
								<>
									<Typography
										mr="1rem"
										variant="h2"
										color={theme.palette.grey[20]}
										sx={{
											textDecoration: 'line-through',
										}}>
										{price}kr
									</Typography>
									<Typography
										variant="h1"
										color={theme.palette.grey[30]}
										textAlign="right">
										{price - Math.floor(price * 0.2)}kr
									</Typography>
								</>
							) : (
								<Typography
									variant="h1"
									color={theme.palette.grey[30]}
									textAlign="right">
									{price}kr
								</Typography>
							)}
							<Typography
								variant="h3"
								textAlign="left"
								color={theme.palette.grey[20]}>
								/{amount} bilder
							</Typography>
						</Box>
						<Box>
							<Typography
								variant="body1"
								color={theme.palette.grey[30]}
								textAlign="left">
								{packText}
							</Typography>
						</Box>
					</Box>

					<Box
						width="100%"
						onClick={() => doPayment(type)}>
						<vipps-mobilepay-button
							type="button"
							brand="vipps"
							language="no"
							variant="primary"
							rounded="false"
							verb="pay"
							stretched="true"
							branded="true"
							loading={hasClicked ? 'true' : 'false'}
						/>
					</Box>
				</Box>
			</Box>
		);
	};
	return (
		<Stack
			direction="column"
			spacing={theme.spacing(3)}
			justifyContent="center"
			alignItems="center"
			backgroundColor={theme.palette.grey[5000]}
			mt={theme.spacing(8)}
			mb={theme.spacing(8)}
			sx={{
				width: '100%',
				maxWidth: '500px',
				minWidth: '360px',
				borderRadius: '20px',
			}}>
			<Box
				display="flex"
				flexDirection="column"
				justifyContent="center"
				alignItems="center">
				<CustomInputTextField
					key="promoCodeInput"
					label="Promo Kode"
					onChange={SetPromoterCode}
					adornment={<AccountCircle />}
					placeholder=""
					tooltip="Har du noen ønsker?"
				/>
				{isVerified ? (
					<Typography color={theme.palette.primary.main}>
						Denne promokoden fungerer!
					</Typography>
				) : (
					<></>
				)}
			</Box>

			{profile.isAdmin ? (
				<TokenPurchaseBox
					amount={2}
					price="1"
					type="10 Mynter"
					packname="Juksepakka"
					target="For admins!"
					packText="Denne er sjukt bra. Den beste vil mange si. Helt vilt"
					hasOffer={true}
					offerValue={20}
				/>
			) : (
				<></>
			)}
			<TokenPurchaseBox
				amount={10}
				price="79"
				type="50 Mynter"
				packname="Startpakken"
				packText="Start nå! For kun 79 kroner får du 10 bildegenereringer for å forvandle dine rom. Perfekt for å teste ut tjenesten! Alle pakker inkluderer våre AI-verktøy for en ny stil. Få en smak av hva vi kan tilby – kom i gang i dag!"
				target="Start her!"
			/>
			<TokenPurchaseBox
				amount={20}
				price="129"
				type="100 Mynter"
				packname="Standardpakken"
				packText="Ta steget videre! Få 20 bildegenereringer for 129 kroner. Utforsk og finn din stil med våre AI-verktøy. Ideell for de som vil eksperimentere mer og finne sin unike stil. Opplev forskjellen nå!"
				target="Mest populær!"
			/>
			<TokenPurchaseBox
				amount={40}
				price="239"
				type="200 Mynter"
				packname="Plusspakken"
				packText="For de som ønsker mer: 40 bildegenereringer for 239 kroner. Perfekt for større prosjekter eller flere rom. Bruk våre AI-verktøy for å gi rommene en ny stil. Transformér hjemmet ditt med denne omfattende pakken!"
			/>
			<TokenPurchaseBox
				amount={80}
				price="398"
				type="400 Mynter"
				packname="Premium"
				packText="For den ultimate opplevelsen: 80 bildegenereringer for 398 kroner. Dette er pakken for interiørdesignere og profesjonelle. Bruk våre AI-verktøy for en helt ny stil. Invester i en total forvandling av hjemmet ditt nå!"
				target="For designere!"
			/>
			<Button
				sx={{
					backgroundColor: theme.palette.grey[5000],
					color: theme.palette.grey[500],
					border: `1px solid ${'transparent'}`,
					'&:hover': {
						backgroundColor: theme.palette.grey[5000],
						color: theme.palette.grey[500],
						border: `1px solid ${theme.palette.primary.main}`, // Highlight border on hover
					},
					borderRadius: '20px',
					textTransform: 'none',
					margin: theme.spacing(1), // Add margin around buttons
					padding: '8px 8px', // Comfortable padding
					justifyContent: 'center',
					alignItems: 'center',
				}}
				onClick={() => navigate('/priser')}>
				Les mer
			</Button>
		</Stack>
	);
};
