import { CssBaseline, Box } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Home } from 'pages/Home';
import { Callback } from '/src/pages/Callback';
import { PaymentRecieved } from '/src/pages/PaymentRecieved';
import { Feedback } from 'pages/Feedback';
import { Credits } from 'pages/Credits';
import NoPage from '/src/pages/NoPage';
import theme from '/src/theme';
import { Faq } from 'pages/Faq';
import { Terms } from 'pages/Terms';
import { Profile } from 'pages/Profile';
import { Contact } from 'pages/Contact';
import { Navbar } from 'components/Navbar';
import { Footer } from 'components/Footer';
import { MyImages } from 'pages/MyImages';
import { AboutUs } from 'pages/AboutUs';
import { Outlet } from 'react-router-dom';
import { CustomStack } from 'components/CustomStack';
import { Referal } from 'pages/Referal';
import { HelmetProvider } from 'react-helmet-async';

function App() {
	const helmetContext = {};
  return (
    <>
      <div className="app">
			<HelmetProvider context={helmetContext}>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Navbar />
            <Box
              minHeight="100vh"
              justifyContent="center"
              margin="auto"
              marginTop="3rem"
              minWidth="300px"
              maxWidth="1000px">
              <CustomStack>
                <Routes>
                  <Route
                    path="/"
                    element={<Outlet />}>
                    <Route
                      index
                      element={<Home />}
                    />
                    <Route
                      path="redir"
                      element={<Callback />}
                    />
                    <Route
                      path="paymentRecieved"
                      element={<PaymentRecieved />}
                    />
                    <Route
                      path="feedback"
                      element={<Feedback />}
                    />
                    <Route
                      path="priser"
                      element={<Credits />}
                    />
                    <Route
                      path="faq"
                      element={<Faq />}
                    />
                    <Route
                      path="terms"
                      element={<Terms />}
                    />
                    <Route
                      path="profile"
                      element={<Profile />}
                    />
                    <Route
                      path="images"
                      element={<MyImages />}
                    />
                    <Route
                      path="contact"
                      element={<Contact />}
                    />
                    <Route
                      path="about"
                      element={<AboutUs />}
                    />
                    <Route
                      path="referal"
                      element={<Referal />}
                    />
                    <Route
                      path="*"
                      element={<NoPage />}
                    />
                  </Route>
                </Routes>
              </CustomStack>
            </Box>
            {/*<FeedbackButton />*/}
            <Footer />
          </ThemeProvider>
        </BrowserRouter>
			</HelmetProvider>
      </div>
    </>
  );
}

export default App;
