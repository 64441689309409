import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CustomStack = styled(Stack)(() => ({
        mt: "1rem",
        mb: "3rem",
        direction: "column",
        spacing: 2,
        justifyContent: "center",
        alignItems: "center",
        useFlexGap: true,
        flexWrap: "wrap",
}));