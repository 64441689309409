import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CustomButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.tertiary,
    border: `1px solid ${'transparent'}`,
    '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.text.secondary,
        border: `1px solid ${theme.palette.primary.main}`,
    },
    borderRadius: theme.shape.borderRadius,
    textTransform: 'none',
    margin: theme.spacing(1),
    padding: '8px 16px',
    gap: theme.spacing(4),
}));