import { useTheme, Typography, Slider, Box } from '@mui/material';
import { CustomStack } from './CustomStack';

export const SliderField = ({
	label,
	min,
	max,
	defaultValue,
	marks,
	onChange,
	showValue = true
}) => {
	const theme = useTheme();
	return (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				alignContent: 'center',
				gap: theme.spacing(2),
				marginTop: "0.5rem",
				marginBottom: "1rem"
			}}>
			<CustomStack sx={{ width: '100%' }}>
				<Typography
					color={theme.palette.grey[500]}
					mb=".5rem">
					{label}
				</Typography>
				<Slider
					aria-label={label}
					defaultValue={defaultValue}
					valueLabelDisplay={showValue ? "on" : "off"}
					shiftStep={1}
					step={1}
					marks={marks}
					min={min}
					max={max}
					onChange={(_, value) => onChange(value)}
					sx={{
						width: '100%',
						'& .MuiSlider-markLabel': {
							color: theme.palette.grey[500]
						},
					}}
				/>
			</CustomStack>
		</Box>
	);
};
