import { useTheme, Typography, Paper } from '@mui/material';
import { SEO } from 'components/SEO';
import { useEffect } from 'react';

export const Contact = () => {
  const theme = useTheme();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);
  return (
    <>
      <SEO
        title="LønnLyst - Kontakt oss"
        description="Har du noe du vil si eller lurer på om LønnLyst? Send oss en melding?"
        name="Lønnlyst - Kontakt oss"
        type="tool"
      />
      <Typography mt="3rem" variant="h1">Kontakt Oss</Typography>
      <Paper
        square={false}
        elevation={24}
        style={{
          padding: '50px',
          margin: '20px',
          backgroundColor: `${theme.palette.grey[5000]}`,
        }}>
        <Typography
          variant="h2"
          color={theme.palette.grey[500]}>
          Velkommen til Lønnlyst
        </Typography>
        <Typography
          variant="body"
          color={theme.palette.grey[500]}>
          Vi spesialiserer oss på å gjøre AI-teknologi tilgjengelig for alle.
          Med bakgrunn i AI-tjenester innen AI genererte bilder, film, språk og
          skrivetjenester, har vi her utviklet løsninger for som passer for
          alle, og ikke bare tech-entusiaster. På individuelt plan har vi rik
          erfaring og erfaring fra teknologidrevne prosjekter, for blant annet
          kunder som Oslo kommune, KLP, Aker BP, og Technip FMC.
        </Typography>
        <Typography
          variant="h3"
          color={theme.palette.grey[500]}
          sx={{ marginTop: '20px' }}>
          Presse eller samarbeidspartnere
        </Typography>
        <Typography
          variant="body"
          color={theme.palette.grey[500]}>
          Hvis du lurer på noe, send dine spørsmål til oss på: post@lonnlyst.no
        </Typography>
        <Typography
          variant="h3"
          color={theme.palette.grey[500]}
          sx={{ marginTop: '20px' }}>
          Juridisk informasjon
        </Typography>
        <Typography
          variant="body"
          color={theme.palette.grey[500]}>
          Lønnlyst.no er registrert under Pixygon med
          organisasjons nummer: Org.nr 930 723 541
        </Typography>
      </Paper>
    </>
  );
};
