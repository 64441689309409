import { Box, Typography, Stack, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Instagram, Facebook, LinkedIn } from '@mui/icons-material';
import { TikTokIcon } from './TikTokIcon';
import { CustomStack } from './CustomStack';

export const Footer = () => {
  const year = new Date().getFullYear();
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <Box
      backgroundColor="#111" height="150px" width="100%"
      sx={{ width: '100vw', overflow: 'hidden' }}>

      <Stack
        direction="row"
        spacing={2}
        display="flex"
        justifyContent="center">
        <Box onClick={() => (window.open("https://www.instagram.com/lonnlyst", "_blank"))} sx={{ cursor: 'pointer' }}>
          <Instagram />
        </Box>
        <Box onClick={() => (window.open("https://www.facebook.com/lonnlyst", "_blank"))} sx={{ cursor: 'pointer' }}>
          <Facebook />
        </Box>
        <Box onClick={() => (window.open("https://www.tiktok.com/@lonnlyst", "_blank"))} sx={{ cursor: 'pointer' }}>
          <TikTokIcon />
        </Box>
        {/*<LinkedIn
							onClick={() => (window.location.href = "https://www.instagram.com/lonnlyst")} />*/}
      </Stack>
      <CustomStack >
        <Box width="90%" sx={{ cursor: 'pointer' }}>
          <Typography
            onClick={() => navigate("/about")}
            fontSize="14px"
            color="#fff"
            align="center">Om oss</Typography> </Box>
        <Box width="90%" sx={{ cursor: 'pointer' }}>
          <Typography
            onClick={() => navigate("/terms")}
            fontSize="14px"
            color="#fff"
            align="center">Salgsvilkår</Typography> </Box>
        <Box width="90%" sx={{ cursor: 'pointer' }}>
          <Typography
            onClick={() => navigate("/contact")}
            fontSize="14px"
            color="#fff"
            align="center">Kontakt oss</Typography> </Box>
      </CustomStack >
      <Typography
        fontSize="14px"
        m="8px"
        color="#fff"
        align="center">{`© PIXYGON ${year} Org.no: 930723541 `}</Typography>
    </Box>
  );
};
