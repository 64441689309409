import { useEffect, useState } from 'react';
import { useTheme, Button, Box, Stack, Typography } from '@mui/material';
import { AccountCircle, Key } from '@mui/icons-material';
import { CustomTextField } from 'components/CustomTextField';
import { CustomInputTextField } from 'components/CustomInputTextField';

export const Feedback = () => {
	const theme = useTheme();
	const [isFinished, setIsFinished] = useState(false);
	var currentTitle = '';
	var currentFeedback = '';

	const sendFeedback = async () => {
		await fetch(`https://pixygon-server.onrender.com/aiusers/feedback`, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				title: 'Feedback LønnLyst: ' + currentTitle,
				feedback: currentFeedback,
			}),
		});
		setIsFinished(true);
	};
	const SetName = (e) => {
		currentTitle = e.target.value;
	};
	const SetKeywords = (e) => {
		currentFeedback = e.target.value;
	};

	useEffect(() => {
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	}, []);

	return (
		<>
			<Typography
				mt="3rem"
				variant="h1">
				Feedback!
			</Typography>
			{isFinished ? (
				<Stack
					mt="1rem"
					direction="row"
					spacing={2}
					justifyContent="center"
					alignItems="center"
					useFlexGap
					flexWrap="wrap">
						<Typography variant="h5" color={theme.palette.text.secondary} textAlign="center">Feedback sendt!</Typography>
					
				</Stack>
			) : (
				<Box
					fullWidth
					minWidth="350px"
					maxWidth="600px"
					sx={{
						backgroundColor: theme.palette.grey[5000],
						border: `1px solid ${'transparent'}`,
						'&:hover': {
							color: theme.palette.text.secondary,
							border: `1px solid ${theme.palette.primary.main}`,
						},
						borderRadius: '20px',
						padding: '4px 16px',
						alignItems: 'center',
						alignContent: 'center',
						gap: theme.spacing(2),
						margin: '1rem 0rem',
						color: theme.palette.text.secondary,
					}}>
					<CustomInputTextField
						label="Tittel"
						onChange={SetName}
						adornment={<AccountCircle />}
						placeholder="Beskriv problemet ditt så godt som mulig!"
						tooltip="Hva gjelder problemet?"
					/>
					<CustomInputTextField
						label="Feedback"
						onChange={SetKeywords}
						adornment={<Key />}
						multiline={true}
						placeholder="Beskriv problemet ditt så godt som mulig!"
						tooltip="Skriv utfyllende og alt du gjorde før feilen skjedde, så er det lettere for oss å fikse problemet ditt!"
					/>
					<Stack
						mt="1rem"
						direction="row"
						spacing={2}
						justifyContent="center"
						alignItems="center"
						useFlexGap
						flexWrap="wrap">
						<Button
							width="250px"
							sx={{
								backgroundColor: theme.palette.primary.main,
								color: theme.palette.text.tertiary,
								border: `1px solid ${'transparent'}`,
								'&:hover': {
									backgroundColor: theme.palette.primary.main,
									color: theme.palette.text.secondary,
									border: `1px solid ${theme.palette.primary.main}`, // Highlight border on hover
								},
								borderRadius: theme.shape.borderRadius,
								textTransform: 'none',
								margin: theme.spacing(1), // Add margin around buttons
								padding: '8px 16px', // Comfortable padding}}
							}}
							onClick={() => sendFeedback()}>
							Send!
						</Button>
					</Stack>
				</Box>
			)}
		</>
	);
};
