import React, { useState, useEffect } from 'react';
import { Stack, Button, ButtonGroup, useTheme, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const ToggleButton = styled(Button)(({ theme, active, disabled }) => ({
	// Adjusted styles to fit the overall look and feel
	backgroundColor: active ? theme.palette.primary.main : 'transparent',
	color: active ? theme.palette.text.tertiary : theme.palette.grey[500],
	'&:hover': {
		backgroundColor: theme.palette.primary.main
	},
	textTransform: 'none',
	padding: '8px 16px', // Comfortable padding
	disabled: disabled,
	flex: "auto"
}));

export const ToggleButtons = ({ onSelectionChange, values, labels }) => {
	const theme = useTheme();
	const [requestType, setRequestType] = useState(values[0]);
	
	/*
	useEffect(() => {
		onSelectionChange(requestType);
	}, [onSelectionChange, requestType]);
	*/
	const handleSelectionChange = (type) => {
		setRequestType(type);
		onSelectionChange(type);
	};

	return (
		<Stack
			direction="row"
			spacing={theme.spacing(3)}
			justifyContent="center"
			alignItems="center"
			width="90%"
			maxWidth="450px"
			margin="1rem 0rem">
			<ButtonGroup variant="contained" size="large" fullWidth>
			{values.map((valueName, index) => (
				<ToggleButton
					key={valueName}
					theme={theme}
					active={requestType === valueName}
					onClick={() => handleSelectionChange(valueName)}
					disabled={false}>
						<Typography variant="body1" fontSize="1rem" >{labels[index]}</Typography>
					
				</ToggleButton>
			))}
			</ButtonGroup>
		</Stack>
	);
};
