import ReactGA from "react-ga4";
const baseUrl = "https://homedesigns.ai/api/v2";
const token =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImpvQHBpeHlnb24uaW8iLCJuYW1lIjoiSm8gSi4gU3RvcmRhbCIsImV4cCI6MTc0MjQwNDUxN30.7RokggD1SzWXvND0ZKvAYv72PAFX2DnOhAP8elu8wzw";

export const beautifulRedesign = async (
  image,
  design_type,
  room_type,
  design_style,
  ai_intervention,
  no_design,
  prompt,
) => {
  try {
    ReactGA.event({
      category: "Generation",
      action: "Beautiful Redesign",
    });
    const formData = new FormData();
    formData.append("image", image);
    formData.append("design_type", design_type);
    switch (design_type) {
      case "Interior":
        formData.append("room_type", [room_type]);
      case "Exterior":
        formData.append("house_angle", [room_type]);
      case "Garden":
        formData.append("garden_type", [room_type]);
    }
    formData.append("design_style", design_style);
    formData.append("ai_intervention", ai_intervention);
    formData.append("no_design", no_design);
    formData.append("prompt", prompt);
    const redesignResponse = await fetch(baseUrl + `/beautiful_redesign`, {
      method: "POST",
      headers: { Authorization: "Bearer " + token },
      body: formData,
    });
    return await redesignResponse.json();
  } catch (err) {
    console.log("This is the error: " + err);
    return { error: err };
  }
};
export const sketchToRender = async (
  image,
  design_type,
  room_type,
  design_style,
  ai_intervention,
  no_design,
  prompt,
) => {
  try {
    ReactGA.event({
      category: "Generation",
      action: "Sketch to Render",
    });
    const formData = new FormData();
    formData.append("image", image);
    formData.append("design_type", design_type);
    formData.append("ai_intervention", ai_intervention);
    formData.append("no_design", no_design);
    formData.append("design_style", design_style);
    switch (design_type) {
      case "Interior":
        formData.append("room_type", [room_type]);
      case "Exterior":
        formData.append("house_angle", [room_type]);
      case "Garden":
        formData.append("garden_type", [room_type]);
    }
    formData.append("prompt", prompt);
    const redesignResponse = await fetch(baseUrl + `/sketch_to_render`, {
      method: "POST",
      headers: { Authorization: "Bearer " + token },
      body: formData,
    });
    return await redesignResponse.json();
  } catch (err) {
    console.log("This is the error: " + err);
    return { error: err };
  }
};
export const removeFurniture = async (image, masked_image, no_design) => {
  try {
    ReactGA.event({
      category: "Generation",
      action: "Remove Furniture",
    });
    const formData = new FormData();
    formData.append("image", image);
    formData.append("masked_image", masked_image);
    formData.append("no_design", no_design);
    const redesignResponse = await fetch(baseUrl + `/furniture_removal`, {
      method: "POST",
      headers: { Authorization: "Bearer " + token },
      body: formData,
    });
    return await redesignResponse.json();
  } catch (err) {
    console.log("This is the error: " + err);
    return { error: err };
  }
};
export const changeColorTexture = async (
  image,
  masked_image,
  design_type,
  no_design,
  prompt,
  color,
  materials,
  materials_type,
) => {
  try {
    ReactGA.event({
      category: "Generation",
      action: "Change Color",
    });
    const formData = new FormData();
    formData.append("image", image);
    formData.append("masked_image", masked_image);
    formData.append("design_type", design_type);
    formData.append("no_design", no_design);
    formData.append("prompt", prompt);
    formData.append("color", color);
    //formData.append("materials", materials);
    //formData.append("materials_type", materials_type);
    formData.append("object", "Wall");
    const redesignResponse = await fetch(baseUrl + `/change_color_textures`, {
      method: "POST",
      headers: { Authorization: "Bearer " + token },
      body: formData,
    });
    return await redesignResponse.json();
  } catch (err) {
    console.log("This is the error: " + err);
    return { error: err };
  }
};
export const decorStaging = async (
  image,
  masked_image,
  design_type,
  no_design,
  design_style,
  room_type,
  prompt,
  transparent,
) => {
  try {
    ReactGA.event({
      category: "Generation",
      action: "Decor Staging",
    });
    const formData = new FormData();
    formData.append("image", image);
    formData.append("masked_image", masked_image);
    formData.append("design_type", design_type);
    formData.append("no_design", no_design);
    formData.append("design_style", design_style);
    switch (design_type) {
      case "Interior":
        formData.append("room_type", [room_type]);
      case "Exterior":
        formData.append("house_angle", [room_type]);
      case "Garden":
        formData.append("garden_type", [room_type]);
    }
    formData.append("prompt", prompt);
    formData.append("transparent", transparent);
    const redesignResponse = await fetch(baseUrl + `/decor_staging`, {
      method: "POST",
      headers: { Authorization: "Bearer " + token },
      body: formData,
    });
    return await redesignResponse.json();
  } catch (err) {
    console.log("This is the error: " + err);
    return { error: err };
  }
};
export const inpainting = async (
  image,
  masked_image,
  design_type,
  no_design,
  design_style,
  room_type,
  prompt,
) => {
  try {
    ReactGA.event({
      category: "Generation",
      action: "Fill Room",
    });
    const formData = new FormData();
    formData.append("image", image);
    formData.append("masked_image", masked_image);
    formData.append("design_type", design_type);
    formData.append("no_design", no_design);
    formData.append("design_style", design_style);
    switch (design_type) {
      case "Interior":
        formData.append("room_type", [room_type]);
      case "Exterior":
        formData.append("house_angle", [room_type]);
      case "Garden":
        formData.append("garden_type", [room_type]);
    }
    formData.append("prompt", prompt);
    const redesignResponse = await fetch(baseUrl + `/fill_spaces`, {
      method: "POST",
      headers: { Authorization: "Bearer " + token },
      body: formData,
    });
    return await redesignResponse.json();
  } catch (err) {
    console.log("This is the error: " + err);
    return { error: err };
  }
};
export const newBeautifulRedesign = async (
  image,
  design_type,
  room_type,
  design_style,
  ai_intervention,
  no_design,
  prompt,
) => {
  try {
    const formData = new FormData();
    formData.append("image", image);
    formData.append("design_type", design_type);
    formData.append("room_type", [room_type]);
    formData.append("design_style", design_style);
    formData.append("ai_intervention", ai_intervention);
    formData.append("no_design", no_design);
    formData.append("prompt", prompt);
    const redesignResponse = await fetch(
      "https://pixygon-server.onrender.com/aioperations/beautifulRedesign",
      {
        method: "POST",
        headers: { Authorization: "Bearer " + token },
        body: formData,
      },
    );
    return await redesignResponse.json();
  } catch (err) {
    return err;
  }
};
export const creativeRedesign = async (
  image,
  design_type,
  room_type,
  design_style,
  ai_intervention,
  no_design,
  prompt,
  fill,
) => {
  try {
    ReactGA.event({
      category: "Generation",
      action: "Creative Redesign",
    });
    const formData = new FormData();
    formData.append("image", image);
    formData.append("design_type", design_type);
    switch (design_type) {
      case "Interior":
        formData.append("room_type", [room_type]);
      case "Exterior":
        formData.append("house_angle", [room_type]);
      case "Garden":
        formData.append("garden_type", [room_type]);
    }
    formData.append("design_style", design_style);
    formData.append("ai_intervention", ai_intervention);
    formData.append("no_design", no_design);
    formData.append("prompt", prompt);
    formData.append("fill", fill);
    const redesignResponse = await fetch(baseUrl + `/creative_redesign`, {
      method: "POST",
      headers: { Authorization: "Bearer " + token },
      body: formData,
    });
    return await redesignResponse.json();
  } catch (err) {
    console.log("This is the error: " + err);
    return { error: err };
  }
};
