import {
	Box,
	useTheme,
	LinearProgress,
	Typography,
	Paper,
  Stack,
} from '@mui/material';
import { Edit, Cancel } from '@mui/icons-material';
import { setIsAborted, setIsWriting, setIsFinished } from 'state';
import { useDispatch, useSelector } from 'react-redux';
import ReactGA from 'react-ga4';
import { CustomButton } from './CustomButton';
import { useEffect } from 'react';

export const WaitingScreen = () => {
	const theme = useTheme();
	const dispatch = useDispatch();

	const image = useSelector((state) => state.global.image);
	const actualImage = useSelector((state) => state.global.actualImage);
	const actualMaskImage = useSelector((state) => state.global.actualMaskImage);
	const maskedImage = useSelector((state) => state.global.maskImage);

	const abortGeneration = () => {
		ReactGA.event({
			category: 'Generation',
			action: 'Abort clicked',
		});
		dispatch(setIsAborted({ isAborted: true }));
		dispatch(setIsWriting({ isWriting: false }));
		dispatch(setIsFinished({ isFinished: false }));
	};
	useEffect(() => {
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	}, []);
	return (
		<Stack
			mt="1rem"
			direction="column"
			spacing={2}
			justifyContent="center"
			alignItems="center"
			useFlexGap
			flexWrap="wrap"
			padding="0.2rem">
			<Typography
				variant="h1"
				sx={{
					color: theme.palette.grey[500],
				}}>
				Genererer
			</Typography>
			<Paper
				sx={{
					position: 'relative', // Set position to relative for the container
					width: '100%',
					height: '500px',
				}}>
				<img
					src={image} // First image
					style={{
						width: '100%',
						height: '100%',
						objectFit: 'contain',
						position: 'relative', // Set position to relative for the image
					}}
					alt="Background Image"
				/>
				{maskedImage ? (
					<img
						src={maskedImage} // Second image
						style={{
							width: '100%',
							height: '100%',
							objectFit: 'contain',
							position: 'absolute', // Position the second image absolutely
							top: 0,
							left: 0,
              filter: 'opacity(0.25)', // Apply filter to make black areas transparent and white areas red
           
						}}
						alt="Overlay Image"
					/>
				) : (
					<></>
				)}
			</Paper>
			<Box
        width="90%"
				endIcon={<Edit />}
				loading
				loadingPosition="end"
				variant="contained">
				<LinearProgress color="success" />
			</Box>
			<Typography
				sx={{
					color: theme.palette.grey[500],
				}}>
				Dette tar ca. 30 sekunder!
			</Typography>

			<CustomButton onClick={() => abortGeneration()}>
				<Cancel sx={{ color: theme.palette.primary }} /> Avbryt
			</CustomButton>
		</Stack>
	);
};
