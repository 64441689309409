import { createTheme } from '@mui/material/styles';

export const tokens = {
	grey: {
	  0: '#F4F4F4', // Light grey for backgrounds
	  10: '#D8D8D8', // Slightly darker grey for less prominent elements
	  20: '#9C9C9C', // Slightly darker grey for less prominent elements
	  30: '#4F4F4F', // Slightly darker grey for less prominent elements
	  50: '#EFEFEF', // Ice blue for borders or subtle accents
	  500: '#222222', // Charcoal for primary text for high contrast
	  4000: '#FFFFFF1A', // Slightly transparent white for overlays or masks
	  5000: '#FFFFFF6F', // Slightly transparent white for overlays or masks
	},
	primary: {
	  main: '#4CAF50', // Fresh green for a nature-inspired touch
	  contrastText: '#FEFEFE', // Charcoal text for best readability on primary color
	},
	secondary: {
	  main: '#F4F4F4', // Soft orange for warmth and vibrancy
	  contrastText: '#222222', // Charcoal text for readability on secondary color
	},
	tertiary: {
	  main: '#3F51B5', // Subtle blue for additional depth
	  contrastText: '#FEFEFE', // Charcoal text to ensure readability
	},
  };

export const theme = createTheme({
	palette: {
		primary: tokens.primary,
		secondary: tokens.secondary,
		tertiary: tokens.tertiary,
		grey: tokens.grey,
		background: {
			default: tokens.grey[0], // Use the dark grey for overall background
			paper: tokens.grey[10], // Use the slightly lighter grey for elements with 'paper' elevation
		},
		text: {
			primary: tokens.primary.contrastText, // Use white for primary text
			secondary: tokens.secondary.contrastText, // Use light grey for secondary text
      		tertiary:  tokens.tertiary.contrastText,
		},
	},
	shape: {
		borderRadius: 20,
	},
	spacing: 8,
	typography: {
		fontFamily: 'Jost, Helvetica, Arial, sans-serif',
		fontSize: 16,
		color: tokens.grey[500],
		lineHeight: 1.2,
		h1: {
			fontFamily: 'Jost, sans-serif',
			fontSize: 36,
			fontWeight: 700,
			color: tokens.grey[500],
			lineHeight: 1.2,
		},
		h2: {
			fontFamily: ['Jost', 'sans-serif'].join(','),
			fontSize: 30,
			fontWeight: 600,
			color: tokens.grey[500],
			lineHeight: 1.2,
		},
		h3: {
			fontFamily: ['Jost', 'sans-serif'].join(','),
			fontSize: 24,
			fontWeight: 600,
			color: tokens.grey[500],
			lineHeight: 1.2,
		},
		h4: {
			fontFamily: ['Jost', 'sans-serif'].join(','),
			fontSize: 20,
			fontWeight: 500,
			color: tokens.grey[500],
			lineHeight: 1.2,
		},
		h5: {
			fontFamily: ['Jost', 'sans-serif'].join(','),
			fontSize: 18,
			fontWeight: 500,
			color: tokens.grey[500],
			lineHeight: 1.2,
		},
		h6: {
			fontFamily: ['Jost', 'sans-serif'].join(','),
			fontSize: 16,
			fontWeight: 500,
			color: tokens.grey[500],
			lineHeight: 1.2,
		},
	},
});

theme.spacing = (factor) => `${0.25 * factor}rem`; // This is an example; the default is 8px

export default theme;
