import { Typography, Box } from '@mui/material';
import { TextSplitter } from 'components/TextSplitter';
import { SEO } from 'components/SEO';
import { useEffect } from 'react';

export const Terms = () => {
	const terms = [
		'Omfang:Disse vilkårene gjelder for alle kjøp av digitale tjenester fra Lønnlyst, tilgjengelig på www.Lønnlyst.no. Ved kjøp av tjenestene våre, aksepterer du disse salgsvilkårene i sin helhet.',
		'Tjenester:Lønnlyst tilbyr interiør design ved bruk av AI teknologi..',
		'Betaling:Betaling for tjenestene skjer gjennom betalingsplattformen Vipps. Betalingsinformasjon behandles trygt i samsvar med gjeldende personvernlover.',
		'Leveringstid: Umiddelbart når du trykker kjøp genereres dine bilder.',
		'Brukerrettigheter:Kjøpte bilder er til dine personlige bruk, de kan ikke videreselges eller brukes kommersielt uten tillatelse fra LønnLyst.',
		'Endringer i Tjenesten: LønnLyst forbeholder seg retten til å gjøre endringer i tjenestene eller priser. Eventuelle endringer vil bli kommunisert til brukerne i god tid.',
		'Avbestilling og Oppsigelse: Ingen refusjon gis for allerede gjennomførte kjøp.',
		'Ansvarsbegrensning: LønnLyst er ikke ansvarlig for indirekte tap som følge av bruk av tjenesten, inkludert, men ikke begrenset til, tap av data eller forretningstap.',
		'Personvern:Personopplysninger behandles i henhold til GDPR og personopplysningsloven. Se vår personvernerklæring for mer informasjon.',
		'Levering: Tjenesten er digital og leveres umiddelbart. Kjøper har her umiddelbar tilgang på produktet ved kjøp',
		'Angrerett:Angreretten gjelder inntil 14 dager. Angreretten bortfaller ved nedlasting eller kopiering av bildene.',
		'Avslutte avtalen:Det er ingen bindingstid, du kjøper etter behov og bruk.',
		'Reklamasjon: Reklamasjon på tjenestene og produkter skal rettes til Lønnlyst via kontaktopplysningene på nettsiden vår.',
		'Klage og Tvisteløsning: Klager på tjenestene skal rettes til Lønnlyst via kontaktopplysningene på nettsiden vår. Tvister søkes løst i minnelighet. Ved tvist gjelder norsk lov.',
		'Endringer i Salgsvilkår: Lønnlyst kan endre disse salgsvilkårene. Endringer trer i kraft etter at de er publisert på vår nettside.',
		'Kontaktinformasjon:For spørsmål om disse salgsvilkårene, vennligst kontakt oss via kontaktskjema på vår nettside.',
	];
	useEffect(() => {
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	}, []);
	return (
		<>
			<SEO
				title="LønnLyst - Salgsvilkår"
				description="Alle dine spørsmål om LønnLyst, besvart!"
				name="Lønnlyst - Salgsvilkår"
				type="tool"
			/>
			<Typography
				mt="3rem"
				variant="h1">
				Salgsvilkår
			</Typography>
			<Box
				display="flex"
				flexDirection="column"
				justifyContent="center"
				m="1rem 0rem"
				width="95%">
				<TextSplitter terms={terms} />
			</Box>
		</>
	);
};